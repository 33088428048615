import React from 'react';

import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchModelingCategory,
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnTextSearch
} from '../../ProductsAiSearch.types';
import { I18nText } from '../../../../../../../types';

import { ProductsAiSearchTextModalButton } from '../ProductsAiSearchTextModalButton';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';

interface ProductsAiSearchTextFileAttachmentPreviewProps {
  fileAttachment: ProductsAiSearchFileAttachment;
  i18nTitle?: I18nText;
  modelingCategory: ProductsAiSearchModelingCategory;
  onAiSearch:
    | ProductsAiSearchOnTextSearch
    | ProductsAiSearchOnClipSearch
    | ProductsAiSearchOnClipGlobalSearch;
}

function ProductsAiSearchTextFileAttachmentPreview({
  fileAttachment,
  i18nTitle,
  modelingCategory,
  onAiSearch
}: ProductsAiSearchTextFileAttachmentPreviewProps) {
  return (
    <div className="h-20">
      <ProductsAiSearchTextModalButton
        className="block overflow-hidden focus-focus-visible:ring-offset-0 rounded-md"
        i18nTitle={i18nTitle}
        initialFileAttachment={fileAttachment}
        initialModelingCategorySlug={modelingCategory?.slug}
        onSubmit={onAiSearch}
      >
        <div key={fileAttachment.id} className="relative">
          <ImageHelper
            className="max-h-20 max-w-36"
            src={fileAttachment.file}
            alt={fileAttachment.name}
            version={FileAttachmentItemImageVersions.FullScreenThumb1000x850}
            tooltipAlt
            tooltipAltInteractive
            tooltipAltAppendToBody
          />
        </div>
      </ProductsAiSearchTextModalButton>
    </div>
  );
}
export default ProductsAiSearchTextFileAttachmentPreview;
