import { useState, useCallback, KeyboardEventHandler } from 'react';
import isNumber from 'lodash/isNumber';
import size from 'lodash/size';

import { CacheProductsSearchSuggestInputSuggestType } from '../../CacheProductsSearchSuggestInput.types';

interface CacheProductsSearchSuggestInputKeyboardOptions {
  popoverItems: CacheProductsSearchSuggestInputSuggestType[];
  setLocalState: (value: string) => void;
  handleSubmit: () => void;
}
function useCacheProductsSearchSuggestInputKeyboard({
  popoverItems,
  setLocalState,
  handleSubmit
}: CacheProductsSearchSuggestInputKeyboardOptions) {
  const [popoverActiveIndex, setPopoverActiveIndex] = useState<number>(null);

  const popoverItemsSize = size(popoverItems);
  const popoverLastItemIndex = popoverItemsSize ? popoverItemsSize - 1 : null;

  const handleChangeActiveSuggest = useCallback<
    (cb: (prev: number) => number) => void
  >(
    (cb) => {
      setPopoverActiveIndex((prev) => {
        const newValue = cb(prev);
        const newLocalState = popoverItems[newValue]?.value;
        if (newLocalState) {
          setLocalState(newLocalState);
        }
        return newValue;
      });
    },
    [popoverItems, setLocalState]
  );

  const handleInputKeyDown = useCallback<KeyboardEventHandler<HTMLElement>>(
    (e) => {
      if (e.key === 'Enter') {
        e.preventDefault();
        handleSubmit();
        return;
      }

      if (popoverItemsSize === 0) {
        return;
      }

      if (e.key === 'ArrowUp') {
        e.preventDefault();
        handleChangeActiveSuggest((prev) => {
          if (!prev) {
            return popoverLastItemIndex;
          }
          return prev - 1;
        });
        return;
      }

      if (e.key === 'ArrowDown') {
        e.preventDefault();
        handleChangeActiveSuggest((prev) => {
          if (!isNumber(prev) || prev === popoverLastItemIndex) {
            return 0;
          }
          return prev + 1;
        });
        return;
      }
    },
    [
      popoverItemsSize,
      handleChangeActiveSuggest,
      popoverLastItemIndex,
      handleSubmit
    ]
  );

  const initializePopoverActiveState = useCallback<() => void>(() => {
    setPopoverActiveIndex(null);
  }, []);

  return {
    popoverActiveIndex,
    initializePopoverActiveState,
    handleInputKeyDown
  };
}

export default useCacheProductsSearchSuggestInputKeyboard;
