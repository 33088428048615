import { gql } from 'graphql-request';
import { ID } from '../../../types';

interface CacheSuggestProductsResponseSuggest {
  id: ID;
  type: string;
  text: string;
  score: number;
}

interface CacheSuggestProductsResponseSuggestProducts {
  suggest: CacheSuggestProductsResponseSuggest[];
}

export interface FetchCacheSuggestProductsQueryResponse {
  suggestProducts: CacheSuggestProductsResponseSuggestProducts;
}

export const FETCH_CACHE_SUGGEST_PRODUCTS_QUERY = gql`
  query SuggestProducts($uuid: String) {
    suggestProducts(query: { prefix: $uuid }) {
      suggest
    }
  }
`;
