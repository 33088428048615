import React, { ReactNode } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ClassName,
  GADataType,
  I18nText,
  IsDisabled
} from '../../../../../../../types';
import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchOnClipGlobalSearch
} from '../../ProductsAiSearch.types';

import { ProductsAiSearchClipGlobalModalButtonContent } from './components/ProductsAiSearchClipGlobalModalButtonContent';

import { SimpleModalButton } from '../../../../../../../helpers/buttons/SimpleModalButton';

interface ProductsAiSearchClipGlobalModalButtonProps {
  initialFileAttachment?: ProductsAiSearchFileAttachment;
  children?: ReactNode;
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTextClassName?: ClassName;
  i18nTitle?: I18nText;
  tooltipI18nText?: I18nText;
  onSubmit: ProductsAiSearchOnClipGlobalSearch;
}

function ProductsAiSearchClipGlobalModalButton({
  children,
  className,
  dataGa,
  disabled,
  i18nText,
  i18nTextClassName,
  i18nTitle,
  icon,
  iconClassName,
  initialFileAttachment,
  onSubmit,
  tooltipI18nText
}: ProductsAiSearchClipGlobalModalButtonProps & GADataType) {
  return (
    <SimpleModalButton
      buttonChildren={children}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTextClassName={i18nTextClassName}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tooltipI18nText}
      withoutButtons
    >
      {({ hideModal }) => (
        <ProductsAiSearchClipGlobalModalButtonContent
          initialFileAttachment={initialFileAttachment}
          onSubmit={onSubmit}
          hideModal={hideModal}
        />
      )}
    </SimpleModalButton>
  );
}

export default ProductsAiSearchClipGlobalModalButton;
