import React, { ReactNode } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ClassName,
  GADataType,
  I18nText,
  IsDisabled
} from '../../../../../../../types';
import { ModelingCategorySlug } from '../../../../../../modelingCategories/modelingCategoriesTypes';
import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnTextSearch
} from '../../ProductsAiSearch.types';

import { ProductsAiSearchTextModalButtonContent } from './components/ProductsAiSearchTextModalButtonContent';

import { SimpleModalButton } from '../../../../../../../helpers/buttons/SimpleModalButton';

interface ProductsAiSearchTextModalButtonProps {
  initialFileAttachment?: ProductsAiSearchFileAttachment;
  initialModelingCategorySlug?: ModelingCategorySlug;
  children?: ReactNode;
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  i18nText?: I18nText;
  i18nTitle?: I18nText;
  tooltipI18nText?: I18nText;
  onSubmit: ProductsAiSearchOnTextSearch | ProductsAiSearchOnClipSearch;
}

function ProductsAiSearchTextModalButton({
  initialFileAttachment,
  initialModelingCategorySlug,
  children,
  dataGa,
  disabled,
  icon,
  iconClassName,
  className,
  i18nText,
  i18nTitle,
  tooltipI18nText,
  onSubmit
}: ProductsAiSearchTextModalButtonProps & GADataType) {
  return (
    <SimpleModalButton
      buttonChildren={children}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTitle={i18nTitle}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tooltipI18nText}
      withoutButtons
    >
      {({ hideModal }) => (
        <ProductsAiSearchTextModalButtonContent
          initialFileAttachment={initialFileAttachment}
          initialModelingCategorySlug={initialModelingCategorySlug}
          onSubmit={onSubmit}
          hideModal={hideModal}
        />
      )}
    </SimpleModalButton>
  );
}

export default ProductsAiSearchTextModalButton;
