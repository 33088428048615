import React, { useCallback, useMemo } from 'react';
import compact from 'lodash/compact';

import { ProductsAiSearchProduct } from '../../ProductsAiSearch.types';

import { LinkHelper } from '../../../../../../../helpers/links/LinkHelper';
import {
  ImageItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';
import {
  LightboxWrapper,
  useLightboxWrapper,
  LightboxItems
} from '../../../../../../../helpers/LightboxWrapper';

import { Files } from '../../../../../../../utils/Files';

interface ProductsAiSearchProductPreviewProps {
  product: ProductsAiSearchProduct;
}

function ProductsAiSearchProductPreview({
  product
}: ProductsAiSearchProductPreviewProps) {
  const lightboxItems = useMemo<LightboxItems>(
    () => compact([product]),
    [product]
  );

  const {
    handleLightboxClose,
    handleLightboxNext,
    handleLightboxOpen,
    handleLightboxOpenOnSlide,
    handleLightboxPrev,
    index,
    imagesCount,
    imageItem,
    lightBoxOpened,
    mainSrc,
    prevSrc,
    nextSrc
  } = useLightboxWrapper({
    items: lightboxItems
  });

  const handleOpenLightbox = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (product && Files.isImage(product?.image?.file)) {
        e.preventDefault();
        handleLightboxOpenOnSlide(product);
      }
    },
    [product, handleLightboxOpenOnSlide]
  );

  return (
    <>
      <LinkHelper
        className="block overflow-hidden focus-focus-visible:ring-offset-0 rounded-md"
        href={product?.image?.file}
        target="_blank"
        onClick={handleOpenLightbox}
      >
        <ImageHelper
          key={product?.uuid}
          className="h-20 max-w-36"
          src={product?.image?.file}
          alt={product?.name}
          version={ImageItemImageVersions.BigThumb538x435}
        />
      </LinkHelper>

      <LightboxWrapper
        handleLightboxClose={handleLightboxClose}
        handleLightboxNext={handleLightboxNext}
        handleLightboxOpen={handleLightboxOpen}
        handleLightboxPrev={handleLightboxPrev}
        index={index}
        imagesCount={imagesCount}
        imageItem={imageItem}
        lightBoxOpened={lightBoxOpened}
        mainSrc={mainSrc}
        nextSrc={nextSrc}
        prevSrc={prevSrc}
        withFullScreenButton
        withBaseImageTitle
      />
    </>
  );
}
export default ProductsAiSearchProductPreview;
