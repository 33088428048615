import { BffApiUpdateItemError } from '../../../../types';
import {
  ModelingDetectObjectID,
  ModelingDetectObjectCategory,
  ModelingDetectObjectXY,
  ModelingDetectObjectWidth,
  ModelingDetectObjectHeight,
  ModelingDetectObjectFileAttachmentID,
  ModelingDetectObjectFileAttachmentUUID,
  ModelingDetectObjectFileAttachmentWidth,
  ModelingDetectObjectFileAttachmentHeight,
  ModelingDetectObjectFileAttachmentFile,
  ModelingDetectObjectFileAttachmentName,
  CreateModelingDetectObjectsCacheKeys
} from '../../modelingDetectObjectsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { ModelingDetectObjectBffUrl } from '../../ModelingDetectObjectBffUrl';

interface CreateModelingDetectObjectsOptions {
  cacheKeys?: CreateModelingDetectObjectsCacheKeys;
}

export type CreateModelingDetectObjectsInput = {
  fileAttachmentId?: ModelingDetectObjectFileAttachmentID;
};

export type CreateModelingDetectObjectsResponse = {
  fileAttachment: {
    id: ModelingDetectObjectFileAttachmentID;
    uuid: ModelingDetectObjectFileAttachmentUUID;
    width: ModelingDetectObjectFileAttachmentWidth;
    height: ModelingDetectObjectFileAttachmentHeight;
    file: ModelingDetectObjectFileAttachmentFile;
    name: ModelingDetectObjectFileAttachmentName;
  };
  detectObjects: {
    id: ModelingDetectObjectID;
    category: ModelingDetectObjectCategory;
    x1: ModelingDetectObjectXY;
    x2: ModelingDetectObjectXY;
    y1: ModelingDetectObjectXY;
    y2: ModelingDetectObjectXY;
    centerX: ModelingDetectObjectXY;
    centerY: ModelingDetectObjectXY;
    width: ModelingDetectObjectWidth;
    height: ModelingDetectObjectHeight;
  }[];
};

export type CreateModelingDetectObjectsError = BffApiUpdateItemError;

function useCreateModelingDetectObjects({
  cacheKeys
}: CreateModelingDetectObjectsOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    CreateModelingDetectObjectsInput,
    CreateModelingDetectObjectsResponse,
    CreateModelingDetectObjectsError
  >({ cacheKeys, url: ModelingDetectObjectBffUrl.createDetectObjects() });

  return {
    createModelingDetectObjectsData: postQueryData,
    createModelingDetectObjectsError: postQueryError,
    createModelingDetectObjectsLoading: postQueryLoading,
    createModelingDetectObjectsErrorMessage: postQueryErrorMessage,
    createModelingDetectObjects: postQuery,
    createModelingDetectObjectsReset: postQueryReset
  };
}

export default useCreateModelingDetectObjects;
