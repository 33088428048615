import { BffApiUpdateItemError } from '../../../../types';
import { ProductCategoryID } from '../../../products/productsTypes';
import { ProjectID } from '../../../projects/projectsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { FileAttachmentID } from '../../../fileAttachments/fileAttachmentsTypes';
import {
  MatchResultUUID,
  ClipSearchRequestCacheKeys
} from '../../matchResultsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MatchResultBffUrl } from '../../MatchResultBffUrl';

interface ClipSearchRequestOptions {
  cacheKeys?: ClipSearchRequestCacheKeys;
}

type ClipSearchRequestResponseType = {
  record: {
    id: MatchResultUUID;
  };
};

export type ClipSearchRequestInput = {
  fileAttachmentId: FileAttachmentID;
  productCategoryId: ProductCategoryID;
  projectId?: ProjectID;
  taskId?: TaskID;
};

export type ClipSearchRequestError = BffApiUpdateItemError;

function useClipSearchRequest({ cacheKeys }: ClipSearchRequestOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    ClipSearchRequestInput,
    ClipSearchRequestResponseType,
    ClipSearchRequestError
  >({
    cacheKeys,
    url: MatchResultBffUrl.clipSearchRequest()
  });

  return {
    clipSearchRequestData: postQueryData,
    clipSearchRequestError: postQueryError,
    clipSearchRequestLoading: postQueryLoading,
    clipSearchRequestErrorMessage: postQueryErrorMessage,
    clipSearchRequest: postQuery,
    clipSearchRequestReset: postQueryReset
  };
}

export default useClipSearchRequest;
