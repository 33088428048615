import { BffApiUpdateItemError, ID } from '../../../../types';
import { ProductAngleId } from '../../../products/productsTypes';
import {
  MatchResultUUID,
  MatchSingleObjectByAngleCacheKeys
} from '../../matchResultsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MatchResultBffUrl } from '../../MatchResultBffUrl';

interface MatchSingleObjectByAngleOptions {
  cacheKeys?: MatchSingleObjectByAngleCacheKeys;
}

type MatchSingleObjectByAngleResponseType = {
  record: {
    id: MatchResultUUID;
  };
};

export type MatchSingleObjectByAngleInput = {
  angleId: ProductAngleId;
  labels?: ID[];
};

export type MatchSingleObjectByAngleError = BffApiUpdateItemError;

function useMatchSingleObjectByAngle({
  cacheKeys
}: MatchSingleObjectByAngleOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    MatchSingleObjectByAngleInput,
    MatchSingleObjectByAngleResponseType,
    MatchSingleObjectByAngleError
  >({
    cacheKeys,
    url: MatchResultBffUrl.matchSingleObjectByAngle()
  });

  return {
    matchSingleObjectByAngleData: postQueryData,
    matchSingleObjectByAngleError: postQueryError,
    matchSingleObjectByAngleLoading: postQueryLoading,
    matchSingleObjectByAngleErrorMessage: postQueryErrorMessage,
    matchSingleObjectByAngle: postQuery,
    matchSingleObjectByAngleReset: postQueryReset
  };
}

export default useMatchSingleObjectByAngle;
