import React, { useCallback } from 'react';

import {
  DOWNLOAD_PRODUCTS_SET_QUERY,
  DownloadProductsSetQueryResponse
} from '../../../queries/downloadProductsSet.query';

import { ProductsSetUUID } from '../../../productsSetsTypes';
import { ClassName } from '../../../../../types';

import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';
import { useDownloadProductsSet } from '../../../../productsSets/hooks/useDownloadProductsSet';
import { useDownloadNanoId } from '../../../../downloads/hooks/useDownloadNanoId';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

import { DownloadCache } from '../../../../downloads/DownloadCache';

import { words } from '../../../../../locales/keys';

interface DownloadProductsSetButtonProps {
  className?: ClassName;
  productsSetUuid: ProductsSetUUID;
}

function DownloadProductsSetButton({
  className,
  productsSetUuid
}: DownloadProductsSetButtonProps) {
  const { downloadNanoId } = useDownloadNanoId();

  const {
    downloadProductsSet,
    downloadProductsSetErrorMessage,
    downloadProductsSetLoading
  } = useDownloadProductsSet<DownloadProductsSetQueryResponse>({
    query: DOWNLOAD_PRODUCTS_SET_QUERY,
    cacheKeys: [DownloadCache.indexCacheKey()]
  });

  useShowToastOnErrorChange({ error: downloadProductsSetErrorMessage });

  const handleDownloadProductsSet = useCallback<() => void>(
    () =>
      downloadProductsSet({
        uuid: productsSetUuid,
        deviceNanoId: downloadNanoId
      }),
    [productsSetUuid, downloadNanoId, downloadProductsSet]
  );

  return (
    <PureButtonHelper
      className={
        className ||
        'flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap'
      }
      dataGa="selected-products-set-download-button"
      i18nText={words.download}
      disabled={downloadProductsSetLoading}
      onClick={handleDownloadProductsSet}
    />
  );
}

export default DownloadProductsSetButton;
