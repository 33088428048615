import React, { useCallback } from 'react';
import cl from 'classnames';

import { ModelingDetectObjectID } from '../../../../../../modelingDetectObjects/modelingDetectObjectsTypes';

interface ProductsAiSearchImageDetectProps {
  title?: string;
  clickable?: boolean;
  active?: boolean;
  percentageX1: number;
  percentageX2: number;
  percentageY1: number;
  percentageY2: number;
  id: ModelingDetectObjectID;
  zIndex?: number;
  onSelectChange: (id: ModelingDetectObjectID) => void;
}
function ProductsAiSearchImageDetect({
  title,
  clickable,
  active,
  percentageX1,
  percentageX2,
  percentageY1,
  percentageY2,
  id,
  zIndex,
  onSelectChange
}: ProductsAiSearchImageDetectProps) {
  const handleClick = useCallback<() => void>(() => {
    onSelectChange(id);
  }, [id, onSelectChange]);

  return (
    <div
      title={title}
      style={{
        zIndex: zIndex,
        left: `${percentageX1}%`,
        right: `${percentageX2}%`,
        top: `${percentageY1}%`,
        bottom: `${percentageY2}%`
      }}
      className={cl(
        'absolute ring-2 bg-transparent hover:bg-opacity-20 hover:bg-white',
        clickable && active
          ? 'ring-green-600 bg-opacity-20 bg-white ring-4'
          : null,
        clickable && !active
          ? 'cursor-pointer ring-yellow-400 hover:ring-green-600'
          : null,
        !clickable ? 'ring-gray-300 hover:ring-gray-400' : null
      )}
      onClick={clickable ? handleClick : undefined}
    />
  );
}

export default ProductsAiSearchImageDetect;
