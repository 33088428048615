import {
  useState,
  useCallback,
  useRef,
  useMemo,
  FocusEventHandler,
  useEffect
} from 'react';
import debounce from 'lodash/debounce';

import { useCacheProductsSearchSuggestInputKeyboard } from '../useCacheProductsSearchSuggestInputKeyboard';
import { useCacheProductsSearchSuggestInputItems } from '../useCacheProductsSearchSuggestInputItems';
import { useAutoFocusRefCb } from '../../../../../../common/hooks/useAutoFocusRefCb';

const wait = 500;

interface CacheProductsSearchSuggestInputOptions {
  value?: string;
  autoFocus?: boolean;
  onSubmit: (value: string) => void;
  closePopover: () => void;
  openPopover: () => void;
}
function useCacheProductsSearchSuggestInput({
  value,
  autoFocus,
  onSubmit,
  closePopover,
  openPopover
}: CacheProductsSearchSuggestInputOptions) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { autoFocusRefCb } = useAutoFocusRefCb();

  const inputRefCb = useCallback<(elem: HTMLInputElement) => void>(
    (elem: HTMLInputElement) => {
      inputRef.current = elem;
      if (autoFocus) {
        autoFocusRefCb(elem);
      }
    },
    [autoFocusRefCb, autoFocus]
  );

  const [localState, setLocalState] = useState<string>(value || '');

  useEffect(() => {
    setLocalState(value || '');
  }, [value]);

  const handleSubmit = useCallback<() => void>(() => {
    onSubmit(localState);
    inputRef.current?.blur();
    closePopover();
  }, [localState, onSubmit, closePopover]);

  const handleSuggestClick = useCallback<(suggestValue: string) => void>(
    (suggestValue) => {
      setLocalState(suggestValue);
      onSubmit(suggestValue);
      inputRef.current?.blur();
      closePopover();
    },
    [onSubmit, closePopover]
  );

  const { popoverItems, makeSuggest } =
    useCacheProductsSearchSuggestInputItems();

  const debouncedMakeSuggest = useMemo(
    () => debounce(makeSuggest, wait),
    [makeSuggest]
  );

  const {
    popoverActiveIndex,
    initializePopoverActiveState,
    handleInputKeyDown
  } = useCacheProductsSearchSuggestInputKeyboard({
    popoverItems,
    setLocalState,
    handleSubmit
  });

  const handleInputFocus = useCallback<() => void>(() => {
    openPopover();
    makeSuggest(localState);
  }, [localState, makeSuggest, openPopover]);

  const handleInputBlur = useCallback<FocusEventHandler<HTMLInputElement>>(
    (e) => {
      initializePopoverActiveState();

      if (e.target.value === '' && value) {
        handleSubmit();
      }
    },
    [handleSubmit, initializePopoverActiveState, value]
  );

  const handleInputChange = useCallback(
    ({ target: { value: inputValue } }) => {
      setLocalState(inputValue);
      debouncedMakeSuggest(inputValue);
      initializePopoverActiveState();
      openPopover();
    },
    [debouncedMakeSuggest, initializePopoverActiveState, openPopover]
  );

  return {
    inputRefCb,
    popoverItems,
    localState,
    popoverActiveIndex,
    handleSubmit,
    handleInputFocus,
    handleInputBlur,
    handleInputChange,
    handleInputKeyDown,
    handleSuggestClick
  };
}

export default useCacheProductsSearchSuggestInput;
