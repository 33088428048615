import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchOnClipGlobalSearch
} from '../../ProductsAiSearch.types';
import { I18nText } from '../../../../../../../types';

import { ProductsAiSearchClipGlobalModalButton } from '../ProductsAiSearchClipGlobalModalButton';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';

interface ProductsAiSearchClipGlobalFileAttachmentPreviewProps {
  fileAttachment: ProductsAiSearchFileAttachment;
  i18nTitle?: I18nText;
  onAiSearch: ProductsAiSearchOnClipGlobalSearch;
}

function ProductsAiSearchClipGlobalFileAttachmentPreview({
  fileAttachment,
  i18nTitle,
  onAiSearch
}: ProductsAiSearchClipGlobalFileAttachmentPreviewProps) {
  return (
    <div className="h-20">
      <ProductsAiSearchClipGlobalModalButton
        className="block overflow-hidden focus-focus-visible:ring-offset-0 rounded-md"
        i18nTitle={i18nTitle}
        initialFileAttachment={fileAttachment}
        onSubmit={onAiSearch}
      >
        <div key={fileAttachment.id} className="relative">
          <ImageHelper
            className="max-h-20 max-w-36"
            src={fileAttachment.file}
            alt={fileAttachment.name}
            version={FileAttachmentItemImageVersions.FullScreenThumb1000x850}
            tooltipAlt
            tooltipAltInteractive
            tooltipAltAppendToBody
          />
        </div>
      </ProductsAiSearchClipGlobalModalButton>
    </div>
  );
}
export default ProductsAiSearchClipGlobalFileAttachmentPreview;
