import { BffApiUpdateItemError } from '../../../../types';
import { ProjectID } from '../../../projects/projectsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { FileAttachmentID } from '../../../fileAttachments/fileAttachmentsTypes';
import {
  ClipGlobalSearchRequestCacheKeys,
  MatchResultUUID
} from '../../matchResultsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MatchResultBffUrl } from '../../MatchResultBffUrl';

interface ClipGlobalSearchRequestOptions {
  cacheKeys?: ClipGlobalSearchRequestCacheKeys;
}

type ClipGlobalSearchRequestResponseType = {
  record: {
    id: MatchResultUUID;
  };
};

export type ClipGlobalSearchRequestInput = {
  fileAttachmentId: FileAttachmentID;
  projectId?: ProjectID;
  taskId?: TaskID;
};

export type ClipGlobalSearchRequestError = BffApiUpdateItemError;

function useClipGlobalSearchRequest({
  cacheKeys
}: ClipGlobalSearchRequestOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    ClipGlobalSearchRequestInput,
    ClipGlobalSearchRequestResponseType,
    ClipGlobalSearchRequestError
  >({
    cacheKeys,
    url: MatchResultBffUrl.clipGlobalSearchRequest()
  });

  return {
    clipGlobalSearchRequestData: postQueryData,
    clipGlobalSearchRequestError: postQueryError,
    clipGlobalSearchRequestLoading: postQueryLoading,
    clipGlobalSearchRequestErrorMessage: postQueryErrorMessage,
    clipGlobalSearchRequest: postQuery,
    clipGlobalSearchRequestReset: postQueryReset
  };
}

export default useClipGlobalSearchRequest;
