import { ID } from '../../../../../types';
import {
  ModelingDetectObjectID,
  ModelingDetectObjectCategory,
  ModelingDetectObjectXY,
  ModelingDetectObjectWidth,
  ModelingDetectObjectHeight,
  ModelingDetectObjectFileAttachmentID,
  ModelingDetectObjectFileAttachmentUUID,
  ModelingDetectObjectFileAttachmentWidth,
  ModelingDetectObjectFileAttachmentHeight,
  ModelingDetectObjectFileAttachmentFile,
  ModelingDetectObjectFileAttachmentName
} from '../../../../modelingDetectObjects/modelingDetectObjectsTypes';
import {
  ModelingCategoryID,
  ModelingCategoryExternalId,
  ModelingCategorySlug
} from '../../../../modelingCategories/modelingCategoriesTypes';

import {
  ProductUUID,
  ProductName,
  ProductImageUUID,
  ProductImageFile,
  ProductAngleId,
  ProductID,
  ProductImageID
} from '../../../productsTypes';

export type ProductsAiSearchFileAttachment = {
  id: ModelingDetectObjectFileAttachmentID;
  uuid: ModelingDetectObjectFileAttachmentUUID;
  width: ModelingDetectObjectFileAttachmentWidth;
  height: ModelingDetectObjectFileAttachmentHeight;
  file: ModelingDetectObjectFileAttachmentFile;
  name: ModelingDetectObjectFileAttachmentName;
};

export type ProductsAiSearchDetectObject = {
  id: ModelingDetectObjectID;
  category: ModelingDetectObjectCategory;
  x1: ModelingDetectObjectXY;
  x2: ModelingDetectObjectXY;
  y1: ModelingDetectObjectXY;
  y2: ModelingDetectObjectXY;
  centerX: ModelingDetectObjectXY;
  centerY: ModelingDetectObjectXY;
  width: ModelingDetectObjectWidth;
  height: ModelingDetectObjectHeight;
};

export type ProductsAiSearchProduct = {
  angleId?: ProductAngleId;
  id: ProductID;
  image: {
    id: ProductImageID;
    uuid: ProductImageUUID;
    file: ProductImageFile;
  };
  name: ProductName;
  uuid: ProductUUID;
};

export type ProductsAiSearchModelingCategory = {
  id: ModelingCategoryID;
  externalId: ModelingCategoryExternalId;
  slug: ModelingCategorySlug;
};

export type ProductsAiSearchDataItemID = ID;

export const enum ProductsAiSearchDataItemType {
  CLIP_GLOBAL_SEARCH = 'clip_global_search',
  CLIP_GLOBAL_SIMILAR_SEARCH = 'clip_global_similar_search',
  CLIP_SEARCH = 'clip_search',
  DETECT_SEARCH = 'detect_search',
  SEARCH_BY_ANGLE = 'search_by_angle',
  TEXT_SEARCH = 'text_search'
}

export type ProductsAiSearchDataItem =
  | {
      id: ProductsAiSearchDataItemID;
      type: ProductsAiSearchDataItemType.DETECT_SEARCH;
      fileAttachment: ProductsAiSearchFileAttachment;
      detectObject: ProductsAiSearchDetectObject;
      allDetectObjects: ProductsAiSearchDetectObject[];
      modelingCategory: ProductsAiSearchModelingCategory;
      product?: never;
    }
  | {
      id: ProductsAiSearchDataItemID;
      type: ProductsAiSearchDataItemType.SEARCH_BY_ANGLE;
      fileAttachment?: never;
      detectObject?: never;
      allDetectObjects?: never;
      modelingCategory?: never;
      product: ProductsAiSearchProduct;
    }
  | {
      id: ProductsAiSearchDataItemID;
      type: ProductsAiSearchDataItemType.TEXT_SEARCH;
      fileAttachment: ProductsAiSearchFileAttachment;
      detectObject?: never;
      allDetectObjects?: never;
      modelingCategory: ProductsAiSearchModelingCategory;
      product?: never;
    }
  | {
      id: ProductsAiSearchDataItemID;
      type: ProductsAiSearchDataItemType.CLIP_SEARCH;
      fileAttachment: ProductsAiSearchFileAttachment;
      detectObject?: never;
      allDetectObjects?: never;
      modelingCategory: ProductsAiSearchModelingCategory;
      product?: never;
    }
  | {
      id: ProductsAiSearchDataItemID;
      type: ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH;
      fileAttachment: ProductsAiSearchFileAttachment;
      detectObject?: never;
      allDetectObjects?: never;
      modelingCategory?: ProductsAiSearchModelingCategory;
      product?: never;
    }
  | {
      id: ProductsAiSearchDataItemID;
      type: ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH;
      fileAttachment?: never;
      detectObject?: never;
      allDetectObjects?: never;
      modelingCategory?: ProductsAiSearchModelingCategory;
      product: ProductsAiSearchProduct;
    };

export type ProductsAiSearchOnNext = () => void;
export type ProductsAiSearchOnPrev = () => void;
export type ProductsAiSearchOnClear = () => void;

export type ProductsAiSearchOnDetect = (data: {
  fileAttachment: ProductsAiSearchFileAttachment;
  detectObject: ProductsAiSearchDetectObject;
  allDetectObjects: ProductsAiSearchDetectObject[];
  modelingCategory: ProductsAiSearchModelingCategory;
}) => void;

export type ProductsAiSearchOnSearchByAngle = (data: {
  product: ProductsAiSearchProduct;
}) => void;

export type ProductsAiSearchOnTextSearch = (data: {
  fileAttachment: ProductsAiSearchFileAttachment;
  modelingCategory: ProductsAiSearchModelingCategory;
}) => void;

export type ProductsAiSearchOnClipSearch = (data: {
  fileAttachment: ProductsAiSearchFileAttachment;
  modelingCategory: ProductsAiSearchModelingCategory;
}) => void;

export type ProductsAiSearchOnClipGlobalSearch = (data: {
  fileAttachment: ProductsAiSearchFileAttachment;
}) => void;

export type ProductsAiSearchOnClipGlobalSimilarSearch = (
  product: ProductsAiSearchProduct
) => void;

export type OnDetectSearchAction = () => void;
export type OnSearchByAngleAction = () => void;
export type OnTextSearchAction = () => void;
export type OnClipSearchAction = () => void;
