import React from 'react';

import {
  ClassName,
  IsDisabled,
  IsOptimisticUpdate
} from '../../../../../../../types';

import {
  ProductsSetSelectedProductsID,
  UpdateProductsSetCacheAction
} from '../../../../../../productsSets/productsSetsTypes';

import { SelectedProductsSidebarAttachModalButtonProductsSet } from './SelectedProductsSidebarAttachModalButton.types';

import { useSelectedProductsSidebarAttachModalButton } from './hooks/useSelectedProductsSidebarAttachModalButton';

import { SelectProjectAndTaskModalButton } from '../../../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { words, productsKeys } from '../../../../../../../locales/keys';

interface SelectedProductsSidebarAttachModalButtonProps {
  selectedProductIds: ProductsSetSelectedProductsID[];
  optimisticUpdate?: IsOptimisticUpdate;
  onClose: () => void;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsSidebarAttachModalButtonProductsSet>;
  className: ClassName;
  disabled?: IsDisabled;
}

function SelectedProductsSidebarAttachModalButton({
  selectedProductIds,
  optimisticUpdate,
  onClose,
  updateProductsSetCache,
  className,
  disabled
}: SelectedProductsSidebarAttachModalButtonProps) {
  const { isLoading, errorMessage, handleSubmit, handleClose } =
    useSelectedProductsSidebarAttachModalButton({
      selectedProductIds,
      onClose,
      updateProductsSetCache
    });

  return (
    <SelectProjectAndTaskModalButton
      className={className}
      dataGa="selected-products-set-attach-modal-button"
      i18nText={words.attach}
      i18nSubmitText={words.attach}
      i18nTitle={productsKeys.attachSelected}
      isLoading={isLoading}
      submitDisabled={optimisticUpdate}
      errorMessage={errorMessage}
      onClose={handleClose}
      onSubmit={handleSubmit}
      disabled={disabled}
    />
  );
}

export default SelectedProductsSidebarAttachModalButton;
