import React, { useCallback } from 'react';

import { UpdateProductsSetCacheAction } from '../../../../../../productsSets/productsSetsTypes';
import { ClassName } from '../../../../../../../types';
import { SelectedProductsSidebarPopoverClearButtonProductsSet } from './SelectedProductsSidebarPopoverClearButton.types';

import { useEmptyProductsSet } from '../../../../../../productsSets/hooks/useEmptyProductsSet';

import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { PureButtonHelper } from '../../../../../../../helpers/buttons/PureButtonHelper';

import { parseRequestError } from '../../../../../../../utils/parseRequestError';

import { words } from '../../../../../../../locales/keys';

import { ProductsSetCache } from '../../../../../../productsSets/ProductsSetCache';

interface SelectedProductsSidebarPopoverClearButtonProps {
  productsSet: SelectedProductsSidebarPopoverClearButtonProductsSet;
  onClose: () => void;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsSidebarPopoverClearButtonProductsSet>;
  className?: ClassName;
}

function SelectedProductsSidebarPopoverClearButton({
  productsSet,
  onClose,
  updateProductsSetCache,
  className
}: SelectedProductsSidebarPopoverClearButtonProps) {
  const uuid = productsSet?.uuid;

  const { emptyProductsSetLoading, emptyProductsSet } = useEmptyProductsSet({
    itemCacheKey: ProductsSetCache.showCacheKey(),
    updateProductsSetCache
  });

  const { showToastNotification } = useToastNotification({
    appearance: 'error'
  });

  const handleEmptyProductsSet = useCallback(() => {
    uuid &&
      emptyProductsSet({ uuid }).catch((error) => {
        showToastNotification(parseRequestError(error));
      });
    onClose();
  }, [uuid, emptyProductsSet, onClose, showToastNotification]);

  return (
    <PureButtonHelper
      className={
        className ||
        'font-medium hover:bg-gray-200 items-center px-4 py-2 rounded-md text-gray-700 text-sm dark:hover:bg-gray-700 dark:text-gray-300 focus:ring-offset-0'
      }
      dataGa="selected-products-set-clear-button"
      i18nText={words.clearSelection}
      disabled={emptyProductsSetLoading}
      onClick={handleEmptyProductsSet}
    />
  );
}

export default SelectedProductsSidebarPopoverClearButton;
