import { useCallback } from 'react';
import cl from 'classnames';

import { IconsEnum } from '../../../../../../assets/icons/types';
import { Icon } from '../../../../../../helpers/Icon';
import { ButtonHelper } from '../../../../../../helpers/buttons/ButtonHelper';
import { CheckPermissions } from '../../../../../../helpers/CheckPermissions';

import { ProductsPermissions } from '../../../../productsConstants';

interface CacheProductsSearchSuggestInputSuggestProps {
  value: string;
  label: string;
  active?: boolean;
  onClick: (value: string) => void;
}
function CacheProductsSearchSuggestInputSuggest({
  value,
  label,
  active,
  onClick
}: CacheProductsSearchSuggestInputSuggestProps) {
  const handleClick = useCallback<() => void>(() => {
    onClick(value);
  }, [value, onClick]);

  return (
    <li
      className={cl(
        'relative bg-gray-500 bg-opacity-0 dark:focus-within:ring-gray-100 duration-150 ease-in-out flex focus-within:ring-2 focus-within:ring-gray-850 group hover:bg-opacity-20 items-center py-2 transition-colors -mx-3 px-2 rounded-md',
        active
          ? 'bg-opacity-25 hover:bg-opacity-30'
          : 'bg-opacity-0 hover:bg-opacity-20'
      )}
    >
      <div className="flex items-center">
        <Icon icon={IconsEnum.SEARCH} />
      </div>
      <ButtonHelper
        className="focus:ring-0 focus:ring-offset-0 focus:outline-none flex-1 px-2 text-sm truncate text-left"
        onClick={handleClick}
        dataGa="products-search-suggest-item"
      >
        {value}
        <span className="absolute inset-0" aria-hidden="true" />
      </ButtonHelper>

      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCTS_SEARCH_SUGGEST_LABEL}
      >
        <div className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight">
          {label}
        </div>
      </CheckPermissions>
    </li>
  );
}

export default CacheProductsSearchSuggestInputSuggest;
