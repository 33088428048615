import React, { useMemo } from 'react';
import size from 'lodash/size';
import map from 'lodash/map';

import { ErrorMessage, ID, IsFetched } from '../../../../../types';

import {
  UpdateProductsSetCacheAction,
  FetchProductsSetsCacheKeys
} from '../../../../productsSets/productsSetsTypes';

import {
  OnSelectedProductsSidebarCloseAction,
  FetchProductsCacheKeys
} from '../../../../products/productsTypes';
import { ProductsListOnProductsSelect } from '../../../../products/components/lists/ProductsList';

import { SelectedProductsSidebarProductsSet } from './SelectedProductsSidebar.types';

import { useTableCheckable } from '../../../../../common/hooks/useTableCheckable';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { SelectedLibraryItemsSidebar } from '../../../../common/components/sidebars/SelectedLibraryItemsSidebar';
import { SelectedProductsSidebarFooter } from './components/SelectedProductsSidebarFooter';

import {
  SelectedProductsList,
  SelectedProductsListProductsSetSelectedProducts
} from '../../lists/SelectedProductsList';

import { SelectedProductsPermissions } from '../../../selectedProductsConstants';

import { productsKeys } from '../../../../../locales/keys';

interface SelectedProductsSidebarProps {
  productsSet: SelectedProductsSidebarProductsSet;
  productsSetFetched: IsFetched;
  productsSetError: ErrorMessage;
  productsSetIsPlaceholderData: boolean;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  onClose: OnSelectedProductsSidebarCloseAction;
  onCustomAttach?: ProductsListOnProductsSelect;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsSidebarProductsSet>;
  withoutBackGround?: boolean;
}

function SelectedProductsSidebar({
  productsSet,
  productsSetFetched,
  productsSetError,
  productsSetIsPlaceholderData,
  productsCacheKeys,
  productsSetCacheKeys,
  onClose,
  onCustomAttach,
  updateProductsSetCache,
  withoutBackGround
}: SelectedProductsSidebarProps) {
  const currentUser = useCurrentUser();

  const selectedProducts =
    useMemo<SelectedProductsListProductsSetSelectedProducts>(
      () => productsSet?.selectedProducts || [],
      [productsSet]
    );

  const defaultCheckedIds = useMemo<ID[]>(
    () => map(selectedProducts, 'id'),
    [selectedProducts]
  );

  const checkable = currentUser.hasPermissions(
    SelectedProductsPermissions.CHANGE_SELECTED_PRODUCTS_CHECKED
  );

  const {
    checkedHash,
    checkedAll,
    handleSetCheckedIds,
    handleCheckAll,
    checkedItems
  } = useTableCheckable({
    items: selectedProducts,
    checkedIds: defaultCheckedIds
  });

  return (
    <SelectedLibraryItemsSidebar
      i18nTitle={productsKeys.selected}
      onClose={onClose}
      footer={
        <SelectedProductsSidebarFooter
          productsSet={productsSet}
          checkedSelectedProductIds={map(checkedItems, 'id')}
          onClose={onClose}
          onCustomAttach={onCustomAttach}
          updateProductsSetCache={updateProductsSetCache}
        />
      }
      selectedItemsCount={size(productsSet.selectedProducts)}
      withoutBackGround={withoutBackGround}
    >
      <SelectedProductsList
        productsSet={productsSet}
        productsSetFetched={productsSetFetched}
        productsSetError={productsSetError}
        productsSetIsPlaceholderData={productsSetIsPlaceholderData}
        productsCacheKeys={productsCacheKeys}
        productsSetCacheKeys={productsSetCacheKeys}
        onSelectedProductsSidebarClose={onClose}
        updateProductsSetCache={updateProductsSetCache}
        checkable={checkable}
        checkedAll={checkedAll}
        checkedHash={checkedHash}
        onCheckAll={handleCheckAll}
        onSetCheckedIds={handleSetCheckedIds}
      />
    </SelectedLibraryItemsSidebar>
  );
}

export default SelectedProductsSidebar;
