import { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import ClickAwayListener from '@mui/base/ClickAwayListener';

import { IconsEnum } from '../../../../assets/icons/types';
import {
  ClassName,
  GADataType,
  IsDisabled,
  PositionStyleEnum
} from '../../../../types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { useCacheProductsSearchSuggestInput } from './hooks/useCacheProductsSearchSuggestInput';
import { useDropdownState } from '../../../../helpers/dropdowns/hooks/useDropdownState';

import { CacheProductsSearchSuggestInputSuggest } from './components/CacheProductsSearchSuggestInputSuggest';

import { productsKeys } from '../../../../locales/keys';
import { Popover } from '../../../../helpers/Popover';
import { PopoverPlacement } from '../../../../helpers/Popover/popoverConstants';
import { Icon } from '../../../../helpers/Icon';

interface CacheProductsSearchSuggestInputProps {
  value?: string;
  autoFocus?: boolean;
  onSubmit: (value: string) => void;
  inputClassName?: ClassName;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  iconWrapperClassName?: ClassName;
  popoverClassName?: ClassName;
  disabled?: IsDisabled;
}
function CacheProductsSearchSuggestInput({
  value,
  autoFocus,
  onSubmit,
  inputClassName,
  icon,
  iconClassName,
  iconWrapperClassName,
  dataGa,
  disabled,
  popoverClassName
}: CacheProductsSearchSuggestInputProps & GADataType) {
  const { t } = useTranslate();

  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    closePopover,
    openPopover
  } = useDropdownState();

  const {
    inputRefCb,
    popoverItems,
    localState,
    popoverActiveIndex,
    handleInputFocus,
    handleInputBlur,
    handleInputChange,
    handleInputKeyDown,
    handleSuggestClick
  } = useCacheProductsSearchSuggestInput({
    value,
    autoFocus,
    onSubmit,
    closePopover,
    openPopover
  });

  return (
    <ClickAwayListener onClickAway={closePopover}>
      <div ref={referencePopoverCallbackRef} className="w-full h-full relative">
        <div
          className={
            iconWrapperClassName ||
            'absolute flex inset-y-0 items-center left-0 ml-3 pointer-events-none'
          }
        >
          <Icon icon={icon || IconsEnum.SEARCH} className={iconClassName} />
        </div>

        <input
          ref={inputRefCb}
          autoComplete="off"
          id="product-elastic-search-text-filter-input"
          data-ga={dataGa}
          value={localState}
          className={inputClassName}
          placeholder={t(productsKeys.search)}
          type="text"
          disabled={disabled}
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
        />

        {localState && !isEmpty(popoverItems) && isPopoverOpen ? (
          <Popover
            className="z-10 pt-1 overflow-hidden pointer-events-none w-full"
            closePopover={closePopover}
            distanceOffset={0}
            positionStyle={PositionStyleEnum.absolute}
            placement={PopoverPlacement.BOTTOM_START}
            referenceElement={referencePopoverElement}
            closeOnOuterClick={false}
            withSameWidth
          >
            <div
              className={
                popoverClassName ||
                'overflow-auto max-h-80 bg-white dark:bg-gray-850 border border-gray-300 dark:border-gray-700 flex flex-col pointer-events-auto rounded-xl dark:glow-lg shadow-xl w-full'
              }
            >
              <div
                id="search-results-dropdown-header"
                className="flex flex-shrink-0"
              />
              <div
                id="search-results-dropdown-body"
                className="flex-grow overflow-y-auto"
              >
                <div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-800 px-4">
                  <div className="py-4">
                    <ul>
                      {popoverItems.map(({ value, label }, index) => (
                        <CacheProductsSearchSuggestInputSuggest
                          active={index === popoverActiveIndex}
                          key={`${index}-${value}`}
                          value={value}
                          label={label}
                          onClick={handleSuggestClick}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                id="search-results-dropdown-footer"
                className="flex flex-shrink-0"
              />
            </div>
          </Popover>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

export default memo<CacheProductsSearchSuggestInputProps & GADataType>(
  CacheProductsSearchSuggestInput
);
