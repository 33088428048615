import { BffApiUpdateItemError } from '../../../../types';
import { ProductID } from '../../../products/productsTypes';
import { ProjectID } from '../../../projects/projectsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import {
  ClipGlobalSimilarSearchCacheKeys,
  MatchResultUUID
} from '../../matchResultsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MatchResultBffUrl } from '../../MatchResultBffUrl';

interface ClipGlobalSimilarSearchOptions {
  cacheKeys?: ClipGlobalSimilarSearchCacheKeys;
}

type ClipGlobalSimilarSearchResponseType = {
  record: {
    id: MatchResultUUID;
  };
};

export type ClipGlobalSimilarSearchInput = {
  productId: ProductID;
  projectId?: ProjectID;
  taskId?: TaskID;
};

export type ClipGlobalSimilarSearchError = BffApiUpdateItemError;

function useClipGlobalSimilarSearch({
  cacheKeys
}: ClipGlobalSimilarSearchOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    ClipGlobalSimilarSearchInput,
    ClipGlobalSimilarSearchResponseType,
    ClipGlobalSimilarSearchError
  >({
    cacheKeys,
    url: MatchResultBffUrl.clipGlobalSimilarSearch()
  });

  return {
    clipGlobalSimilarSearchData: postQueryData,
    clipGlobalSimilarSearchError: postQueryError,
    clipGlobalSimilarSearchLoading: postQueryLoading,
    clipGlobalSimilarSearchErrorMessage: postQueryErrorMessage,
    clipGlobalSimilarSearch: postQuery,
    clipGlobalSimilarSearchReset: postQueryReset
  };
}

export default useClipGlobalSimilarSearch;
