import {
  DownloadProductsSetGqlQuery,
  DownloadProductsSetGqlStatus,
  ProductsSetDeviceNanoId,
  ProductsSetGqlError,
  ProductsSetUUID
} from '../../productsSetsTypes';

import { useUpdateQuery } from '../../../common/hooks/base/reactQuery/useUpdateQuery';

interface DownloadProductsSetOptions {
  query: DownloadProductsSetGqlQuery;
  cacheKeys?: string[];
}

export interface DownloadProductsSetResponse<DownloadProductsSetRecordType> {
  downloadProductsSet: {
    status: DownloadProductsSetGqlStatus;
    recordUuid: ProductsSetUUID;
    record: DownloadProductsSetRecordType;
    errors: DownloadProductsSetError;
  };
}

export interface DownloadProductsSetInput {
  uuid: ProductsSetUUID;
  deviceNanoId: ProductsSetDeviceNanoId;
}

export interface DownloadProductsSetError {
  fullMessages: ProductsSetGqlError;
}

const action = 'downloadProductsSet';

function useDownloadProductsSet<DownloadProductsSetRecordType>({
  query,
  cacheKeys
}: DownloadProductsSetOptions) {
  const {
    updateQueryData,
    updateQuery,
    updateQueryReset,
    updateQueryError,
    updateQueryLoading,
    updateQueryErrorMessage
  } = useUpdateQuery<
    DownloadProductsSetInput,
    DownloadProductsSetResponse<DownloadProductsSetRecordType>,
    DownloadProductsSetError,
    DownloadProductsSetRecordType
  >({ action, query, cacheKeys });

  return {
    downloadProductsSetData: updateQueryData,
    downloadProductsSetError: updateQueryError,
    downloadProductsSetLoading: updateQueryLoading,
    downloadProductsSetErrorMessage: updateQueryErrorMessage,
    downloadProductsSet: updateQuery,
    downloadProductsSetReset: updateQueryReset
  };
}

export default useDownloadProductsSet;
