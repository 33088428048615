import { useCallback } from 'react';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import size from 'lodash/size';

import {
  OnClipSearchAction,
  OnDetectSearchAction,
  OnSearchByAngleAction,
  OnTextSearchAction,
  ProductsAiSearchDataItemType,
  ProductsAiSearchOnClear,
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipGlobalSimilarSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnDetect,
  ProductsAiSearchOnNext,
  ProductsAiSearchOnPrev,
  ProductsAiSearchOnSearchByAngle,
  ProductsAiSearchOnTextSearch
} from '../../ProductsAiSearch.types';

import { useAiSearchDataState } from '../useAiSearchDataState';

import { generateNanoId } from '../../../../../../../utils/generateNanoId';

import { ProductCache } from '../../../../../ProductCache';

interface ProductsAiSearchDataOptions {
  isPersistent?: boolean;
  onDetectSearch?: OnDetectSearchAction;
  onSearchByAngle?: OnSearchByAngleAction;
  onTextSearch?: OnTextSearchAction;
  onClipSearch?: OnClipSearchAction;
}

function useProductsAiSearchData({
  isPersistent,
  onDetectSearch,
  onSearchByAngle,
  onTextSearch,
  onClipSearch
}: ProductsAiSearchDataOptions) {
  const {
    aiSearchData,
    setAiSearchData,
    aiSearchDataItemId,
    setAiSearchDataItemId
  } = useAiSearchDataState({
    cacheKey: ProductCache.aiSearchDataCacheKey(),
    isPersistent
  });

  const currentAiSearchDataItem = aiSearchDataItemId
    ? find(aiSearchData, ['id', aiSearchDataItemId])
    : null;

  const currentAiSearchDataItemIndex = aiSearchDataItemId
    ? findIndex(aiSearchData, ['id', aiSearchDataItemId])
    : null;

  const handleDetectAiSearch = useCallback<ProductsAiSearchOnDetect>(
    (data) => {
      const newItemId = generateNanoId();
      setAiSearchData((prev) => {
        const existingItem = find(
          prev,
          (item) =>
            item?.type === ProductsAiSearchDataItemType.DETECT_SEARCH &&
            item?.fileAttachment?.id === data?.fileAttachment.id &&
            item?.detectObject?.id === data?.detectObject.id &&
            item?.modelingCategory?.id === data?.modelingCategory.id
        );

        if (existingItem) {
          setAiSearchDataItemId(existingItem.id);
          return prev;
        }

        setAiSearchDataItemId(newItemId);
        return [
          ...prev,
          {
            ...data,
            id: newItemId,
            type: ProductsAiSearchDataItemType.DETECT_SEARCH
          }
        ];
      });
      onDetectSearch?.();
    },
    [onDetectSearch, setAiSearchData, setAiSearchDataItemId]
  );

  const handleAiSearchByAngle = useCallback<ProductsAiSearchOnSearchByAngle>(
    ({ product }) => {
      const newItemId = generateNanoId();
      setAiSearchData((prev) => {
        const existingItem = find(
          prev,
          (item) => item?.product?.angleId === product.angleId
        );

        if (existingItem) {
          setAiSearchDataItemId(existingItem.id);
          return prev;
        }

        setAiSearchDataItemId(newItemId);
        return [
          ...prev,
          {
            id: newItemId,
            type: ProductsAiSearchDataItemType.SEARCH_BY_ANGLE,
            product
          }
        ];
      });
      onSearchByAngle?.();
    },
    [onSearchByAngle, setAiSearchData, setAiSearchDataItemId]
  );

  const handleClipGlobalSimilarSearch =
    useCallback<ProductsAiSearchOnClipGlobalSimilarSearch>(
      (product) => {
        const newItemId = generateNanoId();

        setAiSearchData((prev) => {
          const existingItem = find(
            prev,
            (item) =>
              item?.type ===
                ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH &&
              item?.product?.id === product.id
          );

          if (existingItem) {
            setAiSearchDataItemId(existingItem.id);
            return prev;
          }

          setAiSearchDataItemId(newItemId);

          return [
            ...prev,
            {
              id: newItemId,
              type: ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH,
              product
            }
          ];
        });

        onClipSearch?.();
      },
      [onClipSearch, setAiSearchData, setAiSearchDataItemId]
    );

  const handleTextAiSearch = useCallback<ProductsAiSearchOnTextSearch>(
    (data) => {
      const newItemId = generateNanoId();
      setAiSearchData((prev) => {
        const existingItem = find(
          prev,
          (item) =>
            item?.type === ProductsAiSearchDataItemType.TEXT_SEARCH &&
            item?.fileAttachment?.id === data?.fileAttachment.id &&
            item?.modelingCategory?.id === data?.modelingCategory.id
        );

        if (existingItem) {
          setAiSearchDataItemId(existingItem.id);
          return prev;
        }

        setAiSearchDataItemId(newItemId);
        return [
          ...prev,
          {
            ...data,
            id: newItemId,
            type: ProductsAiSearchDataItemType.TEXT_SEARCH
          }
        ];
      });
      onTextSearch?.();
    },
    [onTextSearch, setAiSearchData, setAiSearchDataItemId]
  );

  const handleClipAiSearch = useCallback<ProductsAiSearchOnClipSearch>(
    (data) => {
      const newItemId = generateNanoId();

      setAiSearchData((prev) => {
        const existingItem = find(
          prev,
          (item) =>
            item?.type === ProductsAiSearchDataItemType.CLIP_SEARCH &&
            item?.fileAttachment?.id === data?.fileAttachment.id &&
            item?.modelingCategory?.id === data?.modelingCategory.id
        );

        if (existingItem) {
          setAiSearchDataItemId(existingItem.id);
          return prev;
        }

        setAiSearchDataItemId(newItemId);

        return [
          ...prev,
          {
            ...data,
            id: newItemId,
            type: ProductsAiSearchDataItemType.CLIP_SEARCH
          }
        ];
      });
      onClipSearch?.();
    },
    [onClipSearch, setAiSearchData, setAiSearchDataItemId]
  );

  const handleClipGlobalAiSearch =
    useCallback<ProductsAiSearchOnClipGlobalSearch>(
      (data) => {
        const newItemId = generateNanoId();

        setAiSearchData((prev) => {
          const existingItem = find(
            prev,
            (item) =>
              item?.type === ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH &&
              item?.fileAttachment?.id === data?.fileAttachment.id
          );

          if (existingItem) {
            setAiSearchDataItemId(existingItem.id);
            return prev;
          }

          setAiSearchDataItemId(newItemId);

          return [
            ...prev,
            {
              ...data,
              id: newItemId,
              type: ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH
            }
          ];
        });
        onClipSearch?.();
      },
      [onClipSearch, setAiSearchData, setAiSearchDataItemId]
    );

  const handleClearAiSearch = useCallback<ProductsAiSearchOnClear>(() => {
    setAiSearchData([]);
    setAiSearchDataItemId(null);
  }, [setAiSearchData, setAiSearchDataItemId]);

  const handleNextAiSearch = useCallback<ProductsAiSearchOnNext>(() => {
    if (size(aiSearchData) <= currentAiSearchDataItemIndex + 1) {
      return;
    }
    setAiSearchDataItemId(aiSearchData[currentAiSearchDataItemIndex + 1]?.id);
  }, [aiSearchData, currentAiSearchDataItemIndex, setAiSearchDataItemId]);

  const handlePrevAiSearch = useCallback<ProductsAiSearchOnPrev>(() => {
    if (currentAiSearchDataItemIndex === 0) {
      return;
    }
    setAiSearchDataItemId(aiSearchData[currentAiSearchDataItemIndex - 1]?.id);
  }, [aiSearchData, currentAiSearchDataItemIndex, setAiSearchDataItemId]);

  return {
    aiSearchData,
    currentAiSearchDataItem,
    handleAiSearchByAngle,
    handleClearAiSearch,
    handleClipAiSearch,
    handleClipGlobalAiSearch,
    handleClipGlobalSimilarSearch,
    handleDetectAiSearch,
    handleNextAiSearch,
    handlePrevAiSearch,
    handleTextAiSearch
  };
}

export default useProductsAiSearchData;
