import { useState } from 'react';

import { useReactQueryState } from '../../../../../../common/hooks/base/reactQuery/useReactQueryState';

import {
  ProductsAiSearchDataItem,
  ProductsAiSearchDataItemID
} from '../../ProductsAiSearch.types';

interface AiSearchDataStateOptions {
  cacheKey?: string;
  isPersistent?: boolean;
}

function useAiSearchDataState({
  cacheKey,
  isPersistent
}: AiSearchDataStateOptions) {
  const { value: aiSearchDataReactQuery, setValue: setAiSearchDataReactQuery } =
    useReactQueryState<ProductsAiSearchDataItem[]>(cacheKey, []);

  const {
    value: aiSearchDataItemIdReactQuery,
    setValue: setAiSearchDataItemIdReactQuery
  } = useReactQueryState<ProductsAiSearchDataItemID>(
    `${cacheKey}-item-id`,
    null
  );

  const [aiSearchData, setAiSearchData] = useState<ProductsAiSearchDataItem[]>(
    []
  );

  const [aiSearchDataItemId, setAiSearchDataItemId] =
    useState<ProductsAiSearchDataItemID>(null);

  if (isPersistent) {
    return {
      aiSearchData: aiSearchDataReactQuery,
      setAiSearchData: setAiSearchDataReactQuery,
      aiSearchDataItemId: aiSearchDataItemIdReactQuery,
      setAiSearchDataItemId: setAiSearchDataItemIdReactQuery
    };
  }

  return {
    aiSearchData,
    setAiSearchData,
    aiSearchDataItemId,
    setAiSearchDataItemId
  };
}

export default useAiSearchDataState;
