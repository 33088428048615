import React, { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

import { ID } from '../../../../../../../types';

import { ModelingDetectObjectID } from '../../../../../../modelingDetectObjects/modelingDetectObjectsTypes';

import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchDetectObject
} from '../../ProductsAiSearch.types';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useFileAttachmentDimensions } from '../../../../../../fileAttachments/hooks/useFileAttachmentDimensions';

import { ProductsAiSearchImageDetect } from '../ProductsAiSearchImageDetect';

import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../helpers/ImageHelper';

import { productsAiSearchImageGetPercentagePosition } from '../../utils/productsAiSearchImageGetPercentagePosition';

import { ProductsPermissions } from '../../../../../productsConstants';

interface ProductsAiSearchImageProps {
  detectObjects: ProductsAiSearchDetectObject[];
  fileAttachment: ProductsAiSearchFileAttachment;
  selectedDetectObjectId: ModelingDetectObjectID;
  onSelectChange: (id: ModelingDetectObjectID) => void;
}
function ProductsAiSearchImage({
  detectObjects,
  fileAttachment,
  selectedDetectObjectId,
  onSelectChange
}: ProductsAiSearchImageProps) {
  const currentUser = useCurrentUser();

  const imageDimensions = useFileAttachmentDimensions(fileAttachment);

  const zIndexesById = useMemo<Record<ID, number>>(() => {
    const result = {};

    const sortedFromBigToSmall = sortBy(detectObjects, ({ x1, x2, y1, y2 }) => {
      const width = x2 - x1;
      const height = y2 - y1;
      return -(width * height);
    });

    sortedFromBigToSmall.forEach((detectedObject, index) => {
      result[detectedObject.id] = index;
    });

    return result;
  }, [detectObjects]);

  if (!fileAttachment) {
    return null;
  }

  return (
    <div className="flex bg-gray-50 dark:bg-gray-800 ">
      <div className="m-auto relative overflow-hidden">
        <ImageHelper
          className="object-cover bg-gray-100 dark:bg-gray-850"
          src={fileAttachment.file}
          alt={fileAttachment.name}
          version={FileAttachmentItemImageVersions.FullScreenThumb1000x850}
          tooltipAlt
          tooltipAltInteractive
          tooltipAltAppendToBody
        />
        {imageDimensions?.width &&
          imageDimensions?.height &&
          detectObjects.map((item) => (
            <ProductsAiSearchImageDetect
              key={item.id}
              title={
                currentUser.hasPermissions(
                  ProductsPermissions.READ_PRODUCTS_AI_SEARCH_DETECTED_ITEM_TITLE
                )
                  ? JSON.stringify(item, null, 2)
                  : undefined
              }
              clickable
              id={item.id}
              zIndex={zIndexesById[item.id]}
              active={selectedDetectObjectId === item.id}
              percentageX1={productsAiSearchImageGetPercentagePosition(
                imageDimensions.width,
                item.x1
              )}
              percentageX2={productsAiSearchImageGetPercentagePosition(
                imageDimensions.width,
                item.x2,
                {
                  reverse: true
                }
              )}
              percentageY1={productsAiSearchImageGetPercentagePosition(
                imageDimensions.height,
                item.y1
              )}
              percentageY2={productsAiSearchImageGetPercentagePosition(
                imageDimensions.height,
                item.y2,
                { reverse: true }
              )}
              onSelectChange={onSelectChange}
            />
          ))}
      </div>
    </div>
  );
}

export default ProductsAiSearchImage;
