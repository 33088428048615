import React, { useCallback, useState, useEffect } from 'react';
import first from 'lodash/first';
import find from 'lodash/find';
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { ModelingCategorySlug } from '../../../../../../../../modelingCategories/modelingCategoriesTypes';
import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnTextSearch
} from '../../../../ProductsAiSearch.types';

import {
  FETCH_FILE_ATTACHMENTS_QUERY,
  FetchFileAttachmentsQueryResponse
} from '../../../../../../../../fileAttachments/queries/fetchFileAttachments.query';

import { useFileAttachments } from '../../../../../../../../fileAttachments/hooks/useFileAttachments';

import { useModelingCategories } from '../../../../../../../../modelingCategories/hooks/useModelingCategories';

import {
  ModelingCategoriesSelect,
  ModelingCategoriesSelectCategory,
  ModelingCategoriesSelectSearchTypes
} from '../../../../../../../../modelingCategories/helpers/ModelingCategoriesSelect';
import {
  ImageHelper,
  FileAttachmentItemImageVersions
} from '../../../../../../../../../helpers/ImageHelper';
import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';
import { Translate } from '../../../../../../../../../helpers/Translate';
import { LoadingSkeleton } from '../../../../../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import {
  DropzoneHelper,
  DropzoneHelperFileIds,
  DropzoneHelperFiles
} from '../../../../../../../../../helpers/dropzone/DropzoneHelper';

import { normalizeModelingCategorySlug } from '../../../../../../../../modelingCategories/utils/normalizeModelingCategorySlug';

import {
  words,
  stringsKeys,
  productsKeys
} from '../../../../../../../../../locales/keys';

import { FileAttachmentCache } from '../../../../../../../../fileAttachments/FileAttachmentCache';

interface ProductsAiSearchTextModalButtonContentProps {
  initialFileAttachment?: ProductsAiSearchFileAttachment;
  initialModelingCategorySlug?: ModelingCategorySlug;
  onSubmit: ProductsAiSearchOnTextSearch | ProductsAiSearchOnClipSearch;
  hideModal: () => void;
}

function ProductsAiSearchTextModalButtonContent({
  initialFileAttachment,
  initialModelingCategorySlug,
  onSubmit,
  hideModal
}: ProductsAiSearchTextModalButtonContentProps) {
  const [categorySlug, setCategorySlug] = useState<ModelingCategorySlug>(
    initialModelingCategorySlug
  );

  const [uploadedFileIds, setUploadedFileIds] = useState<DropzoneHelperFileIds>(
    initialFileAttachment ? [initialFileAttachment.id] : []
  );

  const fileAttachmentId = first(uploadedFileIds);

  const {
    fileAttachments,
    fileAttachmentsError,
    fileAttachmentsFetched,
    fileAttachmentsFilters,
    filterFileAttachments
  } = useFileAttachments<FetchFileAttachmentsQueryResponse>({
    cacheKey: FileAttachmentCache.fileAttachmentsDropzoneHelper(),
    query: FETCH_FILE_ATTACHMENTS_QUERY,
    initialFilters: {
      id: {
        in: uploadedFileIds || []
      }
    },
    initialLimit: 1,
    options: {
      enabled: !isEmpty(uploadedFileIds),
      cacheTime: 0,
      enabledPlaceholder: false
    }
  });

  const fileAttachment = find(fileAttachments, ['id', fileAttachmentId]);

  useEffect(() => {
    if (!isEqual(fileAttachmentsFilters.id?.in, uploadedFileIds)) {
      filterFileAttachments({
        id: { in: uploadedFileIds }
      });
    }
  }, [uploadedFileIds, fileAttachmentsFilters, filterFileAttachments]);

  const initialFiles: DropzoneHelperFiles = isEmpty(uploadedFileIds)
    ? {}
    : mapValues(keyBy(fileAttachments, 'uuid'), (attachment) => ({
        activeTab: 'general',
        id: attachment.uuid,
        name: attachment.name,
        file: attachment.file,
        size: attachment.size,
        progresses: { 0: 100 },
        state: 'finished' as const,
        uploadedId: attachment.id
      }));

  const { modelingCategories } = useModelingCategories();
  const selectedModelingCategory = find(modelingCategories, [
    'slug',
    normalizeModelingCategorySlug(categorySlug)
  ]);

  const handleCategoryChange = useCallback<
    (category: ModelingCategoriesSelectCategory) => void
  >((category) => {
    setCategorySlug(category?.slug);
  }, []);

  const handleSubmit = useCallback<() => void>(() => {
    onSubmit({
      fileAttachment,
      modelingCategory: selectedModelingCategory
    });
    hideModal();
  }, [selectedModelingCategory, fileAttachment, hideModal, onSubmit]);

  return (
    <>
      <div className="p-4">
        <div className="text-sm mb-4">
          <p>
            <Translate id={stringsKeys.uploadAPhotoOfARoomSelectACategory} />
          </p>
        </div>

        <AlertMessage message={fileAttachmentsError} />
        <LoadingSkeleton
          loaded={isEmpty(uploadedFileIds) || fileAttachmentsFetched}
        >
          <DropzoneHelper
            type="fileAttachments"
            withoutTabs
            maxFiles={1}
            preventMaxFilesOverload
            initialFileIds={uploadedFileIds}
            initialFiles={initialFiles}
            onChange={setUploadedFileIds}
          />
        </LoadingSkeleton>

        {fileAttachment ? (
          <div className="flex bg-gray-50 dark:bg-gray-800 mt-2">
            <div className="m-auto relative overflow-hidden">
              <ImageHelper
                className="object-cover bg-gray-100 dark:bg-gray-850"
                src={fileAttachment.file}
                alt={fileAttachment.name}
                version={
                  FileAttachmentItemImageVersions.FullScreenThumb1000x850
                }
                tooltipAlt
                tooltipAltInteractive
                tooltipAltAppendToBody
              />
            </div>
          </div>
        ) : null}

        <div className="mt-4">
          <span className="text-sm text-left">
            <Translate id={productsKeys.category} />
          </span>

          <ModelingCategoriesSelect
            value={categorySlug}
            searchType={ModelingCategoriesSelectSearchTypes.TEXT}
            onChange={handleCategoryChange}
          />
        </div>
      </div>
      <div className="flex p-4 space-x-1 justify-between">
        <div className="flex space-x-1" />

        <div className="flex space-x-1">
          <PureButtonHelper
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            i18nText={words.cancel}
            onClick={hideModal}
          />

          <PureButtonHelper
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
            i18nText={words.search}
            disabled={!fileAttachment || !selectedModelingCategory}
            onClick={handleSubmit}
          />
        </div>
      </div>
    </>
  );
}

export default ProductsAiSearchTextModalButtonContent;
