import {
  FetchProductCacheKey,
  FetchProductGqlQuery
} from '../../productsTypes';
import { FetchItemGqlQuery, ID, UUID } from '../../../../types';

import { useCacheShowQuery } from '../../../common/hooks/base/reactQuery/useCacheShowQuery';

import { ProductsPermissions } from '../../productsConstants';

interface CacheSuggestProductsResponseSuggest {
  id: ID;
  type: string;
  text: string;
  score: number;
}

interface CacheSuggestProductsResponseSuggestProducts {
  suggest: CacheSuggestProductsResponseSuggest[];
}

interface CacheSuggestProductsResponse {
  suggestProducts: CacheSuggestProductsResponseSuggestProducts;
}

interface CacheSuggestProductsOptions {
  cacheKey: FetchProductCacheKey;
  cacheQuery?: FetchItemGqlQuery;
  options?: {
    cacheTime?: number;
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
  query: FetchProductGqlQuery;
  prefix: string;
}

const itemKey = 'suggestProducts';

function useCacheSuggestProducts({
  cacheKey,
  cacheQuery,
  options = {},
  query,
  prefix
}: CacheSuggestProductsOptions) {
  const {
    item,
    itemError,
    itemErrorData,
    itemFetched,
    itemLoading,
    itemIsPlaceholderData
  } = useCacheShowQuery<
    CacheSuggestProductsResponse,
    CacheSuggestProductsResponseSuggestProducts
  >({
    action: ProductsPermissions.READ_SUGGEST_PRODUCTS_ES_QUERY,
    cacheKey,
    cacheQuery,
    itemKey,
    options,
    query,
    uuid: prefix as UUID
  });

  return {
    suggestProducts: item,
    suggestProductsErrorData: itemErrorData,
    suggestProductsError: itemError,
    suggestProductsFetched: itemFetched,
    suggestProductsLoading: itemLoading,
    suggestProductsIsPlaceholderData: itemIsPlaceholderData
  };
}

export default useCacheSuggestProducts;
