import { gql } from 'graphql-request';

import { ProductsSetUUID } from '../productsSetsTypes';

export interface DownloadProductsSetQueryResponse {
  uuid: ProductsSetUUID;
}

export const DOWNLOAD_PRODUCTS_SET_QUERY = gql`
  mutation DownloadProductsSet($uuid: ID!, $deviceNanoId: String!) {
    downloadProductsSet(input: { uuid: $uuid, deviceNanoId: $deviceNanoId }) {
      recordUuid
      status
      record {
        uuid
      }
      errors {
        fullMessages
      }
    }
  }
`;
