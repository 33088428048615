import { BffApiUpdateItemError } from '../../../../types';
import { ProductCategoryID } from '../../../products/productsTypes';
import { ProjectID } from '../../../projects/projectsTypes';
import { TaskID } from '../../../tasks/tasksTypes';
import { FileAttachmentID } from '../../../fileAttachments/fileAttachmentsTypes';
import {
  MatchResultUUID,
  TextSearchRequestCacheKeys
} from '../../matchResultsTypes';

import { useBffPostQuery } from '../../../common/hooks/base/reactQuery/useBffPostQuery';

import { MatchResultBffUrl } from '../../MatchResultBffUrl';

interface TextSearchRequestOptions {
  cacheKeys?: TextSearchRequestCacheKeys;
}

type TextSearchRequestResponseType = {
  record: {
    id: MatchResultUUID;
  };
};

export type TextSearchRequestInput = {
  fileAttachmentId: FileAttachmentID;
  productCategoryId: ProductCategoryID;
  projectId?: ProjectID;
  taskId?: TaskID;
};

export type TextSearchRequestError = BffApiUpdateItemError;

function useTextSearchRequest({ cacheKeys }: TextSearchRequestOptions) {
  const {
    postQueryData,
    postQueryError,
    postQueryLoading,
    postQueryErrorMessage,
    postQuery,
    postQueryReset
  } = useBffPostQuery<
    TextSearchRequestInput,
    TextSearchRequestResponseType,
    TextSearchRequestError
  >({
    cacheKeys,
    url: MatchResultBffUrl.textSearchRequest()
  });

  return {
    textSearchRequestData: postQueryData,
    textSearchRequestError: postQueryError,
    textSearchRequestLoading: postQueryLoading,
    textSearchRequestErrorMessage: postQueryErrorMessage,
    textSearchRequest: postQuery,
    textSearchRequestReset: postQueryReset
  };
}

export default useTextSearchRequest;
