import React, { ReactNode } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ClassName,
  GADataType,
  I18nText,
  IsDisabled
} from '../../../../../../../types';
import { ModelingDetectObjectID } from '../../../../../../modelingDetectObjects/modelingDetectObjectsTypes';
import { ModelingCategorySlug } from '../../../../../../modelingCategories/modelingCategoriesTypes';
import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchDetectObject,
  ProductsAiSearchOnDetect
} from '../../ProductsAiSearch.types';

import { ProductsAiSearchDetectModalButtonContent } from './components/ProductsAiSearchDetectModalButtonContent';

import { SimpleModalButton } from '../../../../../../../helpers/buttons/SimpleModalButton';

import { aiSearchKeys } from '../../../../../../../locales/keys';

interface ProductsAiSearchDetectModalButtonProps {
  initialFileAttachment?: ProductsAiSearchFileAttachment;
  initialDetectObjects?: ProductsAiSearchDetectObject[];
  initialSelectedDetectObjectId?: ModelingDetectObjectID;
  initialModelingCategorySlug?: ModelingCategorySlug;
  children?: ReactNode;
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  i18nText?: I18nText;
  tooltipI18nText?: I18nText;
  onSubmit: ProductsAiSearchOnDetect;
}

function ProductsAiSearchDetectModalButton({
  children,
  className,
  dataGa,
  disabled,
  i18nText,
  icon,
  iconClassName,
  initialDetectObjects,
  initialFileAttachment,
  initialModelingCategorySlug,
  initialSelectedDetectObjectId,
  onSubmit,
  tooltipI18nText
}: ProductsAiSearchDetectModalButtonProps & GADataType) {
  return (
    <SimpleModalButton
      buttonChildren={children}
      childrenClassName="flex-1 overflow-y-auto px-2 z-0"
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nText={i18nText}
      i18nTitle={aiSearchKeys.aiPoweredSearch}
      icon={icon}
      iconClassName={iconClassName}
      tooltipI18nText={tooltipI18nText}
      withoutButtons
    >
      {({ hideModal }) => (
        <ProductsAiSearchDetectModalButtonContent
          initialFileAttachment={initialFileAttachment}
          initialDetectObjects={initialDetectObjects}
          initialSelectedDetectObjectId={initialSelectedDetectObjectId}
          initialModelingCategorySlug={initialModelingCategorySlug}
          onSubmit={onSubmit}
          hideModal={hideModal}
        />
      )}
    </SimpleModalButton>
  );
}

export default ProductsAiSearchDetectModalButton;
