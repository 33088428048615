import { useCallback } from 'react';
import find from 'lodash/find';

import {
  FetchProductsFilters,
  OnProductAiSearchAction
} from '../../../../../productsTypes';

import {
  OnClipSearchAction,
  OnDetectSearchAction,
  OnSearchByAngleAction,
  OnTextSearchAction,
  ProductsAiSearchDataItemType
} from '../../ProductsAiSearch.types';

import { useProductsAiSearchData } from '../useProductsAiSearchData';
import { useProductsAiSearchMatchResults } from '../useProductsAiSearchMatchResults';
import { useProductsAiSearchProducts } from '../useProductsAiSearchProducts';

interface ProductsAiSearchOptions {
  isPersistent?: boolean;
  productsFilters: FetchProductsFilters;
  onDetectSearch?: OnDetectSearchAction;
  onSearchByAngle?: OnSearchByAngleAction;
  onTextSearch?: OnTextSearchAction;
  onClipSearch?: OnClipSearchAction;
}

function useProductsAiSearch({
  isPersistent = true,
  productsFilters,
  onDetectSearch,
  onSearchByAngle,
  onTextSearch,
  onClipSearch
}: ProductsAiSearchOptions) {
  const {
    aiSearchData,
    currentAiSearchDataItem,
    handleAiSearchByAngle,
    handleClearAiSearch,
    handleClipAiSearch,
    handleClipGlobalAiSearch,
    handleClipGlobalSimilarSearch,
    handleDetectAiSearch,
    handleNextAiSearch,
    handlePrevAiSearch,
    handleTextAiSearch
  } = useProductsAiSearchData({
    isPersistent,
    onDetectSearch,
    onSearchByAngle,
    onTextSearch,
    onClipSearch
  });

  const {
    matchResultUuid,
    matchResultLoading,
    clipGlobalSimilarSearchErrorMessage
  } = useProductsAiSearchMatchResults({
    productsFilters,
    currentAiSearchDataItem,
    clearAiSearch: handleClearAiSearch
  });

  const {
    aiSearchProducts,
    aiSearchProductsCacheKeys,
    aiSearchProductsError,
    aiSearchProductsFetched,
    aiSearchProductsFetchingNextPage,
    aiSearchProductsIsPlaceholderData,
    aiSearchProductsLimit,
    aiSearchProductsLoading,
    aiSearchProductsPage,
    aiSearchProductsTotalCount,
    hasNextAiSearchProductsPage,
    loadMoreAiSearchProducts,
    updateAiSearchProductCache
  } = useProductsAiSearchProducts({ matchResultUuid });

  const handleProductAiSearch = useCallback<OnProductAiSearchAction>(
    (productId) => {
      const product = find(aiSearchProducts, ['id', productId]);

      if (product) {
        handleAiSearchByAngle({ product });
      }
    },
    [aiSearchProducts, handleAiSearchByAngle]
  );

  return {
    aiSearchData,
    aiSearchProducts,
    aiSearchProductsCacheKeys,
    aiSearchProductsError,
    aiSearchProductsFetched,
    aiSearchProductsFetchingNextPage,
    aiSearchProductsIsPlaceholderData,
    aiSearchProductsLimit,
    aiSearchProductsLoading,
    aiSearchProductsPage,
    aiSearchProductsTotalCount,
    clipGlobalSimilarSearchErrorMessage,
    currentAiSearchDataItem,
    handleDetectAiSearch,
    handleProductAiSearch:
      currentAiSearchDataItem?.type === ProductsAiSearchDataItemType.TEXT_SEARCH
        ? undefined
        : handleProductAiSearch,
    handleClipGlobalSimilarSearch,
    handleClearAiSearch,
    handleClipAiSearch,
    handleClipGlobalAiSearch,
    handleNextAiSearch,
    handlePrevAiSearch,
    handleTextAiSearch,
    hasNextAiSearchProductsPage,
    loadMoreAiSearchProducts,
    matchResultLoading,
    updateAiSearchProductCache
  };
}

export default useProductsAiSearch;
