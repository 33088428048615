import { useMemo } from 'react';

import { FetchProductsCacheKeys } from '../../../../../productsTypes';

import { MatchResultUUID } from '../../../../../../matchResults/matchResultsTypes';

import { useMatchResultProducts } from '../../../../../../products/hooks/useMatchResultProducts';

import { ProductCache } from '../../../../../ProductCache';

const initialFilters = {
  nda: false
};

interface ProductsAiSearchProductsOptions {
  matchResultUuid: MatchResultUUID;
}

function useProductsAiSearchProducts({
  matchResultUuid
}: ProductsAiSearchProductsOptions) {
  const cacheKey = ProductCache.matchResultProductsCacheKey(matchResultUuid);

  const aiSearchProductsCacheKeys = useMemo<FetchProductsCacheKeys>(
    () => [cacheKey],
    [cacheKey]
  );

  const {
    products: aiSearchProducts,
    productsError: aiSearchProductsError,
    productsTotalCount: aiSearchProductsTotalCount,
    productsFetched: aiSearchProductsFetched,
    productsFetchingNextPage: aiSearchProductsFetchingNextPage,
    productsIsPlaceholderData: aiSearchProductsIsPlaceholderData,
    productsLoading: aiSearchProductsLoading,
    hasNextProductsPage: hasNextAiSearchProductsPage,
    updateProductCache: updateAiSearchProductCache,
    loadMoreProducts: loadMoreAiSearchProducts,
    productsPage: aiSearchProductsPage,
    productsLimit: aiSearchProductsLimit
  } = useMatchResultProducts({
    cacheKey,
    matchResultUuid,
    initialFilters,
    options: {
      staleTime: 1000 * 60 * 60,
      enabled: !!matchResultUuid,
      enabledPlaceholder: !!matchResultUuid
    }
  });

  return {
    aiSearchProducts,
    aiSearchProductsCacheKeys,
    aiSearchProductsError,
    aiSearchProductsFetched,
    aiSearchProductsFetchingNextPage,
    aiSearchProductsIsPlaceholderData,
    aiSearchProductsLimit,
    aiSearchProductsLoading,
    aiSearchProductsPage,
    aiSearchProductsTotalCount,
    hasNextAiSearchProductsPage,
    loadMoreAiSearchProducts,
    updateAiSearchProductCache
  };
}

export default useProductsAiSearchProducts;
