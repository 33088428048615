import {
  FetchProductsFilters,
  FetchProductsFilterFields
} from '../../../productsTypes';

export const aiSearchableFilterKeys: (keyof FetchProductsFilters)[] = [
  FetchProductsFilterFields.BRAND_ID,
  FetchProductsFilterFields.STYLE_ID,
  FetchProductsFilterFields.MATERIAL_IDS
];
