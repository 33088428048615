function productsAiSearchImageGetPercentagePosition(
  size: number,
  part: number,
  options: { reverse?: boolean } = {}
) {
  const partPercentage = part / (size / 100);
  const percentage = options.reverse ? 100 - partPercentage : partPercentage;
  return percentage;
}

export default productsAiSearchImageGetPercentagePosition;
