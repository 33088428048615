import { useCallback, useEffect, useMemo, useState } from 'react';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import map from 'lodash/map';
import concat from 'lodash/concat';
import sortBy from 'lodash/sortBy';
import isEqual from 'lodash/isEqual';
import isArray from 'lodash/isArray';
import pick from 'lodash/pick';

import { ID, IdFilterType } from '../../../../../../../types';
import { FetchProductsFilters } from '../../../../../productsTypes';
import {
  ProductsAiSearchDataItem,
  ProductsAiSearchDataItemID,
  ProductsAiSearchDataItemType,
  ProductsAiSearchOnClear
} from '../../ProductsAiSearch.types';
import { ProductsAiSearchMatchResultsMatchData } from './useProductsAiSearchMatchResults.types';

import { useClipGlobalSearchRequest } from '../../../../../../matchResults/hooks/useClipGlobalSearchRequest';
import { useClipGlobalSimilarSearch } from '../../../../../../matchResults/hooks/useClipGlobalSimilarSearch';
import { useClipSearchRequest } from '../../../../../../matchResults/hooks/useClipSearchRequest';
import { useMatchSingleObjectByAngle } from '../../../../../../matchResults/hooks/useMatchSingleObjectByAngle';
import { useMatchSingleObjectByDetectedBounding } from '../../../../../../matchResults/hooks/useMatchSingleObjectByDetectedBounding';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';
import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';
import { useTextSearchRequest } from '../../../../../../matchResults/hooks/useTextSearchRequest';

import { generateNanoId } from '../../../../../../../utils/generateNanoId';

import { aiSearchableFilterKeys } from '../../productsAiSearchConstants';

interface ProductsAiSearchMatchResultsOptions {
  clearAiSearch?: ProductsAiSearchOnClear;
  currentAiSearchDataItem?: ProductsAiSearchDataItem;
  productsFilters: FetchProductsFilters;
}

function useProductsAiSearchMatchResults({
  clearAiSearch,
  currentAiSearchDataItem,
  productsFilters
}: ProductsAiSearchMatchResultsOptions) {
  const searchLabels = useMemo<ID[]>(() => {
    const searchableFilters = pick(productsFilters, aiSearchableFilterKeys);

    const searchableFilterIds = concat(
      ...map(searchableFilters, (filterValue) => {
        if (isArray(filterValue)) {
          return filterValue as ID[];
        }
        if (isArray((filterValue as IdFilterType)?.in)) {
          return (filterValue as IdFilterType).in as ID[];
        }
        return null;
      })
    );

    return sortBy(searchableFilterIds);
  }, [productsFilters]);

  const [matchDataBySearchDataId, setMatchDataBySearchDataId] = useState<
    Record<ProductsAiSearchDataItemID, ProductsAiSearchMatchResultsMatchData[]>
  >({});

  const [currentMatchDataItemId, setCurrentMatchDataItemId] =
    useState<ID>(null);

  const {
    matchSingleObjectByDetectedBounding,
    matchSingleObjectByDetectedBoundingErrorMessage
  } = useMatchSingleObjectByDetectedBounding({});

  const { matchSingleObjectByAngle, matchSingleObjectByAngleErrorMessage } =
    useMatchSingleObjectByAngle({});

  const { textSearchRequest, textSearchRequestErrorMessage } =
    useTextSearchRequest({});

  const { clipSearchRequest, clipSearchRequestErrorMessage } =
    useClipSearchRequest({});

  const { clipGlobalSearchRequest, clipGlobalSearchRequestErrorMessage } =
    useClipGlobalSearchRequest({});

  const { clipGlobalSimilarSearch, clipGlobalSimilarSearchErrorMessage } =
    useClipGlobalSimilarSearch({});

  useShowToastOnErrorChange({
    error: matchSingleObjectByDetectedBoundingErrorMessage
  });

  useShowToastOnErrorChange({
    error: matchSingleObjectByAngleErrorMessage
  });

  useShowToastOnErrorChange({
    error: textSearchRequestErrorMessage
  });

  useShowToastOnErrorChange({
    error: clipSearchRequestErrorMessage
  });

  useShowToastOnErrorChange({
    error: clipGlobalSearchRequestErrorMessage
  });

  const handleUpdateMatchDataItem = useCallback<
    (options: {
      searchDataId: ProductsAiSearchDataItemID;
      matchDataItem: ProductsAiSearchMatchResultsMatchData;
    }) => void
  >(({ searchDataId, matchDataItem }) => {
    if (!matchDataItem.id) {
      return;
    }
    setMatchDataBySearchDataId((prev) => {
      const allMatchData = [...(prev[searchDataId] || [])];
      const existingMatchDataItemIndex = findIndex(allMatchData, [
        'id',
        matchDataItem.id
      ]);
      const existingMatchDataItem = allMatchData?.[existingMatchDataItemIndex];

      if (existingMatchDataItem) {
        allMatchData[existingMatchDataItemIndex] = {
          ...existingMatchDataItem,
          ...matchDataItem
        };
      } else {
        allMatchData.push(matchDataItem);
      }

      return { ...prev, [searchDataId]: allMatchData };
    });
  }, []);

  const handleMatch = useCallback<
    (data: { aiSearchDataItem: ProductsAiSearchDataItem; labels: ID[] }) => void
  >(
    ({ aiSearchDataItem, labels }) => {
      const searchDataId = aiSearchDataItem.id;

      const searchDataType = aiSearchDataItem.type;

      const detectSearchParams =
        aiSearchDataItem.type === ProductsAiSearchDataItemType.DETECT_SEARCH
          ? {
              boundingId: aiSearchDataItem.detectObject?.id,
              productCategoryId: aiSearchDataItem.modelingCategory?.externalId,
              labels
            }
          : null;

      const searchByAngleParams =
        aiSearchDataItem.type === ProductsAiSearchDataItemType.SEARCH_BY_ANGLE
          ? {
              angleId: aiSearchDataItem.product?.angleId,
              labels
            }
          : null;

      const textSearchParams =
        aiSearchDataItem.type === ProductsAiSearchDataItemType.TEXT_SEARCH
          ? {
              fileAttachmentId: aiSearchDataItem.fileAttachment?.id,
              productCategoryId: aiSearchDataItem.modelingCategory?.externalId
            }
          : null;

      const clipGlobalSearchParams =
        aiSearchDataItem.type ===
        ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH
          ? {
              fileAttachmentId: aiSearchDataItem.fileAttachment?.id
            }
          : null;

      const clipGlobalSimilarSearchParams =
        aiSearchDataItem.type ===
        ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH
          ? {
              productId: aiSearchDataItem.product?.id
            }
          : null;

      const clipSearchParams =
        aiSearchDataItem.type === ProductsAiSearchDataItemType.CLIP_SEARCH
          ? {
              fileAttachmentId: aiSearchDataItem.fileAttachment?.id,
              productCategoryId: aiSearchDataItem.modelingCategory?.externalId
            }
          : null;

      const params =
        detectSearchParams ||
        searchByAngleParams ||
        textSearchParams ||
        clipGlobalSearchParams ||
        clipSearchParams;

      const detectDataItemMatchData =
        matchDataBySearchDataId[searchDataId] || [];

      const sameMatchDataItem = find(
        detectDataItemMatchData,
        (matchDataItem) =>
          matchDataItem.searchDataType === searchDataType &&
          isEqual(matchDataItem.params, params)
      );

      if (sameMatchDataItem) {
        setCurrentMatchDataItemId(sameMatchDataItem.id);
        return;
      }

      const newMatchDataItemId = generateNanoId();

      if (searchDataType === ProductsAiSearchDataItemType.DETECT_SEARCH) {
        const matchDataItem: ProductsAiSearchMatchResultsMatchData = {
          id: newMatchDataItemId,
          matchResultLoading: true,
          searchDataType: ProductsAiSearchDataItemType.DETECT_SEARCH,
          params: detectSearchParams
        };
        handleUpdateMatchDataItem({ searchDataId, matchDataItem });
        setCurrentMatchDataItemId(newMatchDataItemId);

        matchSingleObjectByDetectedBounding(matchDataItem.params).then(
          (data) => {
            handleUpdateMatchDataItem({
              searchDataId,
              matchDataItem: {
                ...matchDataItem,
                matchResultLoading: false,
                matchResultUuid: data.record?.id
              }
            });
          }
        );
        return;
      }

      if (searchDataType === ProductsAiSearchDataItemType.SEARCH_BY_ANGLE) {
        const matchDataItem: ProductsAiSearchMatchResultsMatchData = {
          id: newMatchDataItemId,
          matchResultLoading: true,
          searchDataType: ProductsAiSearchDataItemType.SEARCH_BY_ANGLE,
          params: searchByAngleParams
        };
        handleUpdateMatchDataItem({ searchDataId, matchDataItem });
        setCurrentMatchDataItemId(newMatchDataItemId);

        matchSingleObjectByAngle(matchDataItem.params).then((data) => {
          handleUpdateMatchDataItem({
            searchDataId,
            matchDataItem: {
              ...matchDataItem,
              matchResultLoading: false,
              matchResultUuid: data.record?.id
            }
          });
        });
        return;
      }

      if (
        searchDataType ===
        ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH
      ) {
        const matchDataItem: ProductsAiSearchMatchResultsMatchData = {
          id: newMatchDataItemId,
          matchResultLoading: true,
          searchDataType:
            ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH,
          params: clipGlobalSimilarSearchParams
        };

        handleUpdateMatchDataItem({ searchDataId, matchDataItem });

        setCurrentMatchDataItemId(newMatchDataItemId);

        clipGlobalSimilarSearch(matchDataItem.params)
          .then((data) => {
            handleUpdateMatchDataItem({
              searchDataId,
              matchDataItem: {
                ...matchDataItem,
                matchResultLoading: false,
                matchResultUuid: data.record?.id
              }
            });
          })
          .catch(() => {
            clearAiSearch?.();
          });
        return;
      }

      if (searchDataType === ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH) {
        const matchDataItem: ProductsAiSearchMatchResultsMatchData = {
          id: newMatchDataItemId,
          matchResultLoading: true,
          searchDataType: ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH,
          params: clipGlobalSearchParams
        };

        handleUpdateMatchDataItem({ searchDataId, matchDataItem });

        setCurrentMatchDataItemId(newMatchDataItemId);

        clipGlobalSearchRequest(matchDataItem.params).then((data) => {
          handleUpdateMatchDataItem({
            searchDataId,
            matchDataItem: {
              ...matchDataItem,
              matchResultLoading: false,
              matchResultUuid: data.record?.id
            }
          });
        });
        return;
      }

      if (searchDataType === ProductsAiSearchDataItemType.CLIP_SEARCH) {
        const matchDataItem: ProductsAiSearchMatchResultsMatchData = {
          id: newMatchDataItemId,
          matchResultLoading: true,
          searchDataType: ProductsAiSearchDataItemType.CLIP_SEARCH,
          params: clipSearchParams
        };

        handleUpdateMatchDataItem({ searchDataId, matchDataItem });

        setCurrentMatchDataItemId(newMatchDataItemId);

        clipSearchRequest(matchDataItem.params).then((data) => {
          handleUpdateMatchDataItem({
            searchDataId,
            matchDataItem: {
              ...matchDataItem,
              matchResultLoading: false,
              matchResultUuid: data.record?.id
            }
          });
        });
        return;
      }

      if (searchDataType === ProductsAiSearchDataItemType.TEXT_SEARCH) {
        const matchDataItem: ProductsAiSearchMatchResultsMatchData = {
          id: newMatchDataItemId,
          matchResultLoading: true,
          searchDataType: ProductsAiSearchDataItemType.TEXT_SEARCH,
          params: textSearchParams
        };
        handleUpdateMatchDataItem({ searchDataId, matchDataItem });
        setCurrentMatchDataItemId(newMatchDataItemId);

        textSearchRequest(matchDataItem.params).then((data) => {
          handleUpdateMatchDataItem({
            searchDataId,
            matchDataItem: {
              ...matchDataItem,
              matchResultLoading: false,
              matchResultUuid: data.record?.id
            }
          });
        });
        return;
      }
    },
    [
      clearAiSearch,
      clipGlobalSearchRequest,
      clipGlobalSimilarSearch,
      clipSearchRequest,
      handleUpdateMatchDataItem,
      matchDataBySearchDataId,
      matchSingleObjectByAngle,
      matchSingleObjectByDetectedBounding,
      textSearchRequest
    ]
  );

  const prevSearchLabels = usePreviousValue(searchLabels);

  const prevAiSearchDataItemId = usePreviousValue(currentAiSearchDataItem?.id);

  useEffect(() => {
    if (
      !currentAiSearchDataItem ||
      (isEqual(prevSearchLabels, searchLabels) &&
        prevAiSearchDataItemId === currentAiSearchDataItem?.id)
    ) {
      return;
    }

    handleMatch({
      aiSearchDataItem: currentAiSearchDataItem,
      labels: searchLabels
    });
  }, [
    currentAiSearchDataItem,
    handleMatch,
    prevAiSearchDataItemId,
    prevSearchLabels,
    searchLabels
  ]);

  const matchDataItem = find(
    matchDataBySearchDataId?.[currentAiSearchDataItem?.id],
    ['id', currentMatchDataItemId]
  );

  const matchResultUuid = matchDataItem?.matchResultUuid;
  const matchResultLoading = matchDataItem?.matchResultLoading;

  return {
    matchResultUuid,
    matchResultLoading,
    clipGlobalSimilarSearchErrorMessage
  };
}

export default useProductsAiSearchMatchResults;
