import React from 'react';
import findIndex from 'lodash/findIndex';
import includes from 'lodash/includes';
import size from 'lodash/size';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  ProductsAiSearchDataItem,
  ProductsAiSearchDataItemType,
  ProductsAiSearchOnClear,
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnDetect,
  ProductsAiSearchOnNext,
  ProductsAiSearchOnPrev,
  ProductsAiSearchOnTextSearch
} from './ProductsAiSearch.types';

import { ProductsAiSearchClipGlobalFileAttachmentPreview } from './components/ProductsAiSearchClipGlobalFileAttachmentPreview';
import { ProductsAiSearchDetectFileAttachmentPreview } from './components/ProductsAiSearchDetectFileAttachmentPreview';
import { ProductsAiSearchProductPreview } from './components/ProductsAiSearchProductPreview';
import { ProductsAiSearchTextFileAttachmentPreview } from './components/ProductsAiSearchTextFileAttachmentPreview';

import { ProductsCategoryLocalizedNameById } from '../../../helpers/ProductsCategoryLocalizedNameById';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../helpers/Translate';

import { aiSearchKeys, words } from '../../../../../locales/keys';

interface ProductsAiSearchProps {
  aiSearchData: ProductsAiSearchDataItem[];
  currentAiSearchDataItem: ProductsAiSearchDataItem;
  onClear: ProductsAiSearchOnClear;
  onClipAiSearch: ProductsAiSearchOnClipSearch;
  onClipGlobalAiSearch: ProductsAiSearchOnClipGlobalSearch;
  onDetectAiSearch: ProductsAiSearchOnDetect;
  onNext: ProductsAiSearchOnNext;
  onPrev: ProductsAiSearchOnPrev;
  onTextAiSearch: ProductsAiSearchOnTextSearch;
}

function ProductsAiSearch({
  aiSearchData,
  currentAiSearchDataItem,
  onClear,
  onClipAiSearch,
  onClipGlobalAiSearch,
  onDetectAiSearch,
  onNext,
  onPrev,
  onTextAiSearch
}: ProductsAiSearchProps) {
  const currentNumber =
    findIndex(aiSearchData, ['id', currentAiSearchDataItem.id]) + 1;
  const totalAmount = size(aiSearchData);

  return (
    <div className="px-4">
      <div className="p-2 sm:p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
        <div className="relative flex gap-4 flex-col sm:flex-row">
          <div className="shrink-0 flex sm:flex-col sm:items-center">
            {currentAiSearchDataItem.type ===
            ProductsAiSearchDataItemType.DETECT_SEARCH ? (
              <ProductsAiSearchDetectFileAttachmentPreview
                fileAttachment={currentAiSearchDataItem.fileAttachment}
                detectObject={currentAiSearchDataItem.detectObject}
                allDetectObjects={currentAiSearchDataItem.allDetectObjects}
                modelingCategory={currentAiSearchDataItem.modelingCategory}
                onDetectAiSearch={onDetectAiSearch}
              />
            ) : null}

            {includes(
              [
                ProductsAiSearchDataItemType.SEARCH_BY_ANGLE,
                ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH
              ],
              currentAiSearchDataItem.type
            ) ? (
              <ProductsAiSearchProductPreview
                product={currentAiSearchDataItem.product}
              />
            ) : null}

            {currentAiSearchDataItem.type ===
            ProductsAiSearchDataItemType.TEXT_SEARCH ? (
              <ProductsAiSearchTextFileAttachmentPreview
                fileAttachment={currentAiSearchDataItem.fileAttachment}
                i18nTitle={aiSearchKeys.aiPoweredSearch20}
                modelingCategory={currentAiSearchDataItem.modelingCategory}
                onAiSearch={onTextAiSearch}
              />
            ) : null}

            {currentAiSearchDataItem.type ===
            ProductsAiSearchDataItemType.CLIP_SEARCH ? (
              <ProductsAiSearchTextFileAttachmentPreview
                fileAttachment={currentAiSearchDataItem.fileAttachment}
                i18nTitle={aiSearchKeys.aiPoweredCLIPSearch}
                modelingCategory={currentAiSearchDataItem.modelingCategory}
                onAiSearch={onClipAiSearch}
              />
            ) : null}

            {currentAiSearchDataItem.type ===
            ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH ? (
              <ProductsAiSearchClipGlobalFileAttachmentPreview
                fileAttachment={currentAiSearchDataItem.fileAttachment}
                i18nTitle={aiSearchKeys.imageSearch}
                onAiSearch={onClipGlobalAiSearch}
              />
            ) : null}

            <div className="flex gap-1 items-center sm:mt-2 sm:-mb-2 px-2">
              <PureIconButtonHelper
                className="py-0.5 pl-0.5 pr-0.5 rounded-md items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex"
                icon={IconsEnum.CHEVRON_LEFT}
                iconClassName="h-5 w-5"
                disabled={currentNumber === 1}
                onClick={onPrev}
              />

              <div className="text-sm">
                <span>{currentNumber}</span>/<span>{totalAmount}</span>
              </div>

              <PureIconButtonHelper
                className="py-0.5 pl-0.5 pr-0.5 rounded-md items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base text-gray-500 dark:text-gray-400 hover:text-gray-800 dark:hover:text-gray-100 focus:ring-offset-0 inline-flex"
                icon={IconsEnum.CHEVRON_RIGHT}
                iconClassName="h-5 w-5"
                disabled={size(aiSearchData) <= currentNumber}
                onClick={onNext}
              />
            </div>
          </div>
          <div className="flex-1 py-2 flex min-w-0">
            <div className="w-full">
              <div className="flex mb-1.5 gap-1">
                <div className="uppercase font-bold text-gray-500 text-xs">
                  {includes(
                    [
                      ProductsAiSearchDataItemType.DETECT_SEARCH,
                      ProductsAiSearchDataItemType.SEARCH_BY_ANGLE
                    ],
                    currentAiSearchDataItem.type
                  ) ? (
                    <Translate id={aiSearchKeys.aiPoweredSearch} />
                  ) : null}
                  {currentAiSearchDataItem.type ===
                  ProductsAiSearchDataItemType.TEXT_SEARCH ? (
                    <Translate id={aiSearchKeys.aiPoweredSearch20} />
                  ) : null}
                  {includes(
                    [
                      ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH,
                      ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH
                    ],
                    currentAiSearchDataItem.type
                  ) ? (
                    <Translate id={aiSearchKeys.imageSearch} />
                  ) : null}
                </div>
              </div>

              <p className="truncate w-full flex text-md">
                <span className="truncate">
                  {includes(
                    [
                      ProductsAiSearchDataItemType.DETECT_SEARCH,
                      ProductsAiSearchDataItemType.TEXT_SEARCH,
                      ProductsAiSearchDataItemType.CLIP_SEARCH,
                      ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH
                    ],
                    currentAiSearchDataItem.type
                  )
                    ? currentAiSearchDataItem.fileAttachment?.name
                    : null}
                  {includes(
                    [
                      ProductsAiSearchDataItemType.SEARCH_BY_ANGLE,
                      ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH
                    ],
                    currentAiSearchDataItem.type
                  )
                    ? currentAiSearchDataItem.product?.name
                    : null}
                </span>
              </p>

              <div className="text-xs text-gray-500">
                {includes(
                  [
                    ProductsAiSearchDataItemType.DETECT_SEARCH,
                    ProductsAiSearchDataItemType.TEXT_SEARCH,
                    ProductsAiSearchDataItemType.CLIP_SEARCH,
                    ProductsAiSearchDataItemType.CLIP_GLOBAL_SEARCH
                  ],
                  currentAiSearchDataItem.type
                ) ? (
                  <Translate id={aiSearchKeys.sourceImage} />
                ) : null}
                {includes(
                  [
                    ProductsAiSearchDataItemType.SEARCH_BY_ANGLE,
                    ProductsAiSearchDataItemType.CLIP_GLOBAL_SIMILAR_SEARCH
                  ],
                  currentAiSearchDataItem.type
                ) ? (
                  <Translate id={aiSearchKeys.sourceProduct} />
                ) : null}
              </div>

              {currentAiSearchDataItem?.modelingCategory ? (
                <div className="text-xs text-gray-500">
                  <ProductsCategoryLocalizedNameById
                    productCategoryId={
                      currentAiSearchDataItem.modelingCategory?.externalId
                    }
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <PureIconButtonHelper
              className="py-1.5 pl-1.5 pr-3 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
              dataGa="products-clear-ai-image-search-button"
              i18nText={words.clear}
              icon={IconsEnum.X}
              iconClassName="h-5 w-5"
              onClick={onClear}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ProductsAiSearch;
