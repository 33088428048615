import { gql } from 'graphql-request';
import {
  ProductBrandName,
  ProductBrandUUID,
  ProductBrandID,
  ProductCreatedAt,
  ProductBlocked,
  ProductFavorite,
  ProductID,
  ProductName,
  ProductNda,
  ProductUUID,
  ProductImageUUID,
  ProductImageFile,
  ProductBrandLocalizedName,
  ProductNanoID,
  ProductRenderTypeID,
  ProductRenderTypeName,
  ProductTagID,
  ProductTagName,
  ProductMaxFileCreatedAt,
  ProductImageID
} from '../productsTypes';

export interface CacheFetchProductsQueryResponse {
  blocked: ProductBlocked;
  favorite: ProductFavorite;
  id: ProductID;
  uuid: ProductUUID;
  nanoId: ProductNanoID;
  name: ProductName;
  nda: ProductNda;
  sku: ProductUUID;
  createdAt: ProductCreatedAt;
  updatedAt: ProductCreatedAt;
  renderTypes: {
    id: ProductRenderTypeID;
    name: ProductRenderTypeName;
  }[];
  image: {
    id: ProductImageID;
    uuid: ProductImageUUID;
    file: ProductImageFile;
  };
  maxFile: {
    createdAt: ProductMaxFileCreatedAt;
  };
  brand: {
    id: ProductBrandID;
    uuid: ProductBrandUUID;
    name: ProductBrandName;
    localizedName: ProductBrandLocalizedName;
  };
  tags: {
    id: ProductTagID;
    localizedName: ProductTagName;
  }[];
}

export const CACHE_FETCH_PRODUCTS_QUERY = gql`
  query CacheProducts(
    $filters: ProductsFilters
    $sort: [ProductsSortEnum!]
    $limit: Int
    $offset: Int
    $source: String
    $trackTotalHits: Boolean
  ) {
    products(
      filters: $filters
      sort: $sort
      limit: $limit
      offset: $offset
      source: $source
      trackTotalHits: $trackTotalHits
    ) {
      nodes {
        blocked
        favorite
        id
        uuid
        nanoId
        sku
        name
        nda
        createdAt
        updatedAt
        renderTypes {
          id
          name
        }
        image {
          id
          uuid
          file
        }
        maxFile {
          createdAt
        }
        previewModel {
          id
          uuid
          file
          size
          name
        }
        brand {
          id
          uuid
          name
          localizedName
        }
        tags {
          id
          localizedName
        }
      }
      paginationInfo {
        currentPage
        nextPage
        totalCount
      }
      sql
    }
  }
`;
