import { useMemo, useCallback, useState } from 'react';
import map from 'lodash/map';

import { CacheProductsSearchSuggestInputSuggestType } from '../../CacheProductsSearchSuggestInput.types';

import { FETCH_CACHE_SUGGEST_PRODUCTS_QUERY } from '../../../../queries/fetchCacheSuggestProducts.query';

import { useShowToastOnErrorChange } from '../../../../../../common/hooks/useShowToastOnErrorChange';
import { useCacheSuggestProducts } from '../../../../hooks/useCacheSuggestProducts';

import { ProductCache } from '../../../../ProductCache';

const enum SuggestType {
  NAME = 'name',
  BRAND_NAME = 'brand_name',
  BRAND_NAME_EN = 'brand_name_en',
  BRAND_NAME_UK = 'brand_name_uk',
  SKU = 'sku',
  PRODUCT_CATEGORY_NAME = 'product_category_name',
  PRODUCT_CATEGORY_NAME_EN = 'product_category_name_en',
  PRODUCT_CATEGORY_NAME_UK = 'product_category_name_uk',
  CUSTOM_TAGS_NAME = 'custom_tags_name',
  CUSTOM_TAGS_NAME_EN = 'custom_tags_name_en'
}

const SuggestTypeLabel = {
  [SuggestType.NAME]: 'name',
  [SuggestType.BRAND_NAME]: 'brand',
  [SuggestType.BRAND_NAME_EN]: 'brand',
  [SuggestType.BRAND_NAME_UK]: 'brand',
  [SuggestType.SKU]: 'sku',
  [SuggestType.PRODUCT_CATEGORY_NAME]: 'category',
  [SuggestType.PRODUCT_CATEGORY_NAME_EN]: 'category',
  [SuggestType.PRODUCT_CATEGORY_NAME_UK]: 'category',
  [SuggestType.CUSTOM_TAGS_NAME]: 'custom tags',
  [SuggestType.CUSTOM_TAGS_NAME_EN]: 'custom tags'
};

function useCacheProductsSearchSuggestInputItems() {
  const [prefix, setPrefix] = useState<string>('');

  const { suggestProducts, suggestProductsError } = useCacheSuggestProducts({
    cacheKey: ProductCache.cacheSuggestProductsCacheKey(),
    prefix,
    query: FETCH_CACHE_SUGGEST_PRODUCTS_QUERY,
    cacheQuery: FETCH_CACHE_SUGGEST_PRODUCTS_QUERY,
    options: {
      staleTime: 1000 * 60 * 60,
      enabledPlaceholder: false,
      enabled: !!prefix
    }
  });

  useShowToastOnErrorChange({
    error: suggestProductsError
  });

  const popoverItems = useMemo<
    CacheProductsSearchSuggestInputSuggestType[]
  >(() => {
    const defaultItem = {
      value: prefix,
      label: 'search'
    };

    const suggestItems = map(suggestProducts?.suggest, (item) => ({
      value: item.text,
      label: SuggestTypeLabel[item.type]
    }));

    return [defaultItem, ...suggestItems];
  }, [suggestProducts, prefix]);

  const makeSuggest = useCallback<(newValue: string) => void>((newValue) => {
    setPrefix(newValue);
  }, []);

  return {
    popoverItems,
    makeSuggest
  };
}

export default useCacheProductsSearchSuggestInputItems;
