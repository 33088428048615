import { useState, useEffect } from 'react';

import {
  FileAttachmentFile,
  FileAttachmentWidth,
  FileAttachmentHeight
} from '../../fileAttachmentsTypes';

import { useMountEffect } from '../../../../common/hooks/useMountEffect';

import { getImageUrlDimensions } from '../../../../utils/getImageUrlDimensions';

interface FileAttachmentDimensionsFileAttachment {
  file: FileAttachmentFile;
  width?: FileAttachmentWidth;
  height?: FileAttachmentHeight;
}

function useFileAttachmentDimensions(
  fileAttachment?: FileAttachmentDimensionsFileAttachment
) {
  const [imageDimensions, setImageDimensions] = useState<{
    width?: number;
    height?: number;
  }>({ width: fileAttachment?.width, height: fileAttachment?.height });

  useEffect(() => {
    if (
      (imageDimensions.width &&
        imageDimensions.height &&
        imageDimensions.width === fileAttachment?.width &&
        imageDimensions.height === fileAttachment?.height) ||
      !fileAttachment?.file
    ) {
      return;
    }

    if (
      (fileAttachment?.width &&
        fileAttachment?.height &&
        imageDimensions.width !== fileAttachment?.width) ||
      imageDimensions.height !== fileAttachment?.height
    ) {
      setImageDimensions({
        width: fileAttachment.width,
        height: fileAttachment.height
      });
      return;
    }

    getImageUrlDimensions(fileAttachment.file).then((dimensions) =>
      setImageDimensions(dimensions)
    );
  }, [fileAttachment, imageDimensions]);

  useMountEffect(() => {
    if (
      (imageDimensions?.width && imageDimensions?.height) ||
      !fileAttachment?.file
    ) {
      return;
    }
    getImageUrlDimensions(fileAttachment.file).then((dimensions) =>
      setImageDimensions(dimensions)
    );
  });

  return imageDimensions;
}

export default useFileAttachmentDimensions;
