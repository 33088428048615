import { useRef, useCallback } from 'react';

function useAutoFocusRefCb() {
  const initializedRef = useRef<boolean>(false);

  const autoFocusRefCb = useCallback<(elem: HTMLElement | null) => void>(
    (elem) => {
      if (!elem || initializedRef.current) {
        return;
      }
      initializedRef.current = true;
      setTimeout(() => {
        elem.focus?.();
      }, 100);
    },
    []
  );

  return { autoFocusRefCb };
}

export default useAutoFocusRefCb;
