import { useCallback } from 'react';
import every from 'lodash/every';
import filter from 'lodash/filter';
import size from 'lodash/size';

import {
  ProductsSetSelectedProductsID,
  UpdateProductsSetCacheAction
} from '../../../../../../../../productsSets/productsSetsTypes';

import {
  SubmitSelectedProductsToProjectQueryResponse,
  SUBMIT_SELECTED_PRODUCTS_TO_PROJECT_QUERY
} from '../../../../../../../../projects/queries/submitSelectedProductsToProject.query';

import { useSubmitSelectedProductsToProject } from '../../../../../../../../projects/hooks/useSubmitSelectedProductsToProject';

import {
  SubmitSelectedProductsToTaskQueryResponse,
  SUBMIT_SELECTED_PRODUCTS_TO_TASK_QUERY
} from '../../../../../../../../tasks/queries/submitSelectedProductsToTask.query';

import { useSubmitSelectedProductsToTask } from '../../../../../../../../tasks/hooks/useSubmitSelectedProductsToTask';

import { SelectProjectAndTaskModalButtonOnSubmitAction } from '../../../../../../../../common/components/modalButtons/SelectProjectAndTaskModalButton';

import { ProductsSetCache } from '../../../../../../../../productsSets/ProductsSetCache';

import { SelectedProductsSidebarAttachModalButtonProductsSet } from '../../SelectedProductsSidebarAttachModalButton.types';

interface UseSelectedProductsSidebarAttachModalButtonOptions {
  selectedProductIds: ProductsSetSelectedProductsID[];
  onClose: () => void;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsSidebarAttachModalButtonProductsSet>;
}

function useSelectedProductsSidebarAttachModalButton({
  selectedProductIds,
  onClose,
  updateProductsSetCache
}: UseSelectedProductsSidebarAttachModalButtonOptions) {
  const {
    submitSelectedProductsToProjectErrorMessage,
    submitSelectedProductsToProjectLoading,
    submitSelectedProductsToProject,
    submitSelectedProductsToProjectReset
  } = useSubmitSelectedProductsToProject<SubmitSelectedProductsToProjectQueryResponse>(
    {
      query: SUBMIT_SELECTED_PRODUCTS_TO_PROJECT_QUERY,
      cacheKeys: [ProductsSetCache.showCacheKey()]
    }
  );

  const {
    submitSelectedProductsToTaskErrorMessage,
    submitSelectedProductsToTaskLoading,
    submitSelectedProductsToTask,
    submitSelectedProductsToTaskReset
  } = useSubmitSelectedProductsToTask<SubmitSelectedProductsToTaskQueryResponse>(
    {
      query: SUBMIT_SELECTED_PRODUCTS_TO_TASK_QUERY,
      cacheKeys: [ProductsSetCache.showCacheKey()]
    }
  );

  const handleAfterSubmit = useCallback<() => void>(() => {
    updateProductsSetCache({
      updateFunction: (prevProductSet) => ({
        ...prevProductSet,
        selectedProducts:
          size(selectedProductIds) > 0
            ? filter(prevProductSet.selectedProducts, (selectedProduct) =>
                every(selectedProductIds, (id) => id !== selectedProduct.id)
              )
            : []
      })
    });
    onClose();
  }, [onClose, selectedProductIds, updateProductsSetCache]);

  const handleSubmit =
    useCallback<SelectProjectAndTaskModalButtonOnSubmitAction>(
      ({ projectUuid, taskUuid }) => {
        if (taskUuid) {
          return submitSelectedProductsToTask({
            uuid: taskUuid,
            selectedProductIds
          }).then(() => {
            handleAfterSubmit();
          });
        }
        return submitSelectedProductsToProject({
          uuid: projectUuid,
          selectedProductIds
        }).then(() => {
          handleAfterSubmit();
        });
      },
      [
        submitSelectedProductsToProject,
        selectedProductIds,
        submitSelectedProductsToTask,
        handleAfterSubmit
      ]
    );

  const handleClose = useCallback<() => void>(() => {
    submitSelectedProductsToProjectReset();
    submitSelectedProductsToTaskReset();
  }, [submitSelectedProductsToProjectReset, submitSelectedProductsToTaskReset]);

  return {
    isLoading:
      submitSelectedProductsToProjectLoading ||
      submitSelectedProductsToTaskLoading,
    errorMessage:
      submitSelectedProductsToProjectErrorMessage ||
      submitSelectedProductsToTaskErrorMessage,
    handleSubmit,
    handleClose
  };
}

export default useSelectedProductsSidebarAttachModalButton;
