import React, { Fragment } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { SelectedProductsSidebarActionsPopoverProductsSet } from './SelectedProductsSidebarActionsPopover.types';
import { UpdateProductsSetCacheAction } from '../../../../../../productsSets/productsSetsTypes';

import {
  SelectedProductsSidebarPopoverClearButton,
  SelectedProductsSidebarPopoverClearButtonProductsSet
} from '../SelectedProductsSidebarPopoverClearButton';

import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';
import { PureIconRefButtonHelper } from '../../../../../../../helpers/buttons/PureIconRefButtonHelper';
import { Popover, usePopoverState } from '../../../../../../../helpers/Popover';
interface SelectedProductsSidebarActionsPopoverProps {
  productsSet: SelectedProductsSidebarActionsPopoverProductsSet;
  onClose: () => void;
  updateProductsSetCache: UpdateProductsSetCacheAction<SelectedProductsSidebarPopoverClearButtonProductsSet>;
}

function SelectedProductsSidebarActionsPopover({
  productsSet,
  onClose,
  updateProductsSetCache
}: SelectedProductsSidebarActionsPopoverProps) {
  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    togglePopover,
    closePopover
  } = usePopoverState();

  return (
    <div className="relative">
      <PureIconRefButtonHelper
        className="relative inline-flex items-center px-2 py-2 rounded-r-md border-l border-blue-400 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:z-10 focus:outline-none"
        dataGa="selected-products-set-dropdown"
        icon={IconsEnum.CHEVRON_UP}
        iconClassName="h-5 w-5"
        onClick={togglePopover}
        ref={referencePopoverCallbackRef}
      />

      {isPopoverOpen ? (
        <Popover
          className="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700"
          closePopover={closePopover}
          placement={PopoverPlacement.TOP_START}
          referenceElement={referencePopoverElement}
        >
          {productsSet ? (
            <Fragment>
              <SelectedProductsSidebarPopoverClearButton
                className="flex text-left dark:hover:bg-gray-800 hover:bg-gray-100 px-4 py-2 text-sm w-full whitespace-nowrap"
                productsSet={productsSet}
                onClose={onClose}
                updateProductsSetCache={updateProductsSetCache}
              />
            </Fragment>
          ) : null}
        </Popover>
      ) : null}
    </div>
  );
}

export default SelectedProductsSidebarActionsPopover;
