import React, { useCallback, useState } from 'react';
import cl from 'classnames';
import first from 'lodash/first';
import find from 'lodash/find';

import { ID } from '../../../../../../../../../types';
import { ModelingCategorySlug } from '../../../../../../../../modelingCategories/modelingCategoriesTypes';
import { ModelingDetectObjectID } from '../../../../../../../../modelingDetectObjects/modelingDetectObjectsTypes';
import { ProductsAiSearchDetectModalButtonSteps } from '../../ProductsAiSearchDetectModalButton.types';
import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchDetectObject,
  ProductsAiSearchOnDetect
} from '../../../../ProductsAiSearch.types';

import { useModelingCategories } from '../../../../../../../../modelingCategories/hooks/useModelingCategories';

import { useCreateModelingDetectObjects } from '../../../../../../../../modelingDetectObjects/hooks/useCreateModelingDetectObjects';

import {
  ModelingCategoriesSelect,
  ModelingCategoriesSelectCategory
} from '../../../../../../../../modelingCategories/helpers/ModelingCategoriesSelect';
import { ProductsAiSearchImage } from '../../../ProductsAiSearchImage';
import { PureButtonHelper } from '../../../../../../../../../helpers/buttons/PureButtonHelper';
import { Translate } from '../../../../../../../../../helpers/Translate';
import { Loading } from '../../../../../../../../../helpers/Loading';
import { AlertMessage } from '../../../../../../../../../helpers/AlertMessage';
import {
  DropzoneHelper,
  DropzoneHelperFileIds
} from '../../../../../../../../../helpers/dropzone/DropzoneHelper';

import { normalizeModelingCategorySlug } from '../../../../../../../../modelingCategories/utils/normalizeModelingCategorySlug';

import {
  words,
  stringsKeys,
  productsKeys
} from '../../../../../../../../../locales/keys';

interface ProductsAiSearchDetectModalButtonContentProps {
  initialFileAttachment?: ProductsAiSearchFileAttachment;
  initialDetectObjects?: ProductsAiSearchDetectObject[];
  initialSelectedDetectObjectId?: ModelingDetectObjectID;
  initialModelingCategorySlug?: ModelingCategorySlug;
  onSubmit: ProductsAiSearchOnDetect;
  hideModal: () => void;
}

function ProductsAiSearchDetectModalButtonContent({
  initialFileAttachment,
  initialDetectObjects,
  initialSelectedDetectObjectId,
  initialModelingCategorySlug,
  onSubmit,
  hideModal
}: ProductsAiSearchDetectModalButtonContentProps) {
  const [fileAttachment, setFileAttachment] =
    useState<ProductsAiSearchFileAttachment>(initialFileAttachment);
  const [detectObjects, setDetectObjects] =
    useState<ProductsAiSearchDetectObject[]>(initialDetectObjects);

  const step =
    fileAttachment && detectObjects
      ? ProductsAiSearchDetectModalButtonSteps.SECOND
      : ProductsAiSearchDetectModalButtonSteps.FIRST;

  const [uploadedFileIds, setUploadedFileIds] = useState<DropzoneHelperFileIds>(
    []
  );

  const fileAttachmentId = first(uploadedFileIds);

  const [selectedDetectObjectId, setSelectedDetectObjectId] =
    useState<ModelingDetectObjectID>(initialSelectedDetectObjectId);

  const [categorySlugByDetectObjectId, setCategorySlugByDetectObjectId] =
    useState<Record<ID, ModelingCategorySlug>>(
      initialModelingCategorySlug && initialSelectedDetectObjectId
        ? { [initialSelectedDetectObjectId]: initialModelingCategorySlug }
        : {}
    );

  const categorySlug = categorySlugByDetectObjectId?.[selectedDetectObjectId];

  const { modelingCategories } = useModelingCategories();
  const selectedModelingCategory = find(modelingCategories, [
    'slug',
    normalizeModelingCategorySlug(categorySlug)
  ]);

  const handleCategoryChange = useCallback<
    (category: ModelingCategoriesSelectCategory) => void
  >(
    (category) => {
      if (selectedDetectObjectId) {
        setCategorySlugByDetectObjectId((prev) => ({
          ...prev,
          [selectedDetectObjectId]: category?.slug
        }));
      }
    },
    [selectedDetectObjectId, setCategorySlugByDetectObjectId]
  );

  const handleDetectObjectIdChange = useCallback<(boundingId: ID) => void>(
    (boundingId) => {
      setSelectedDetectObjectId(boundingId);
      if (!categorySlugByDetectObjectId?.[boundingId]) {
        const defaultCategorySlug = normalizeModelingCategorySlug(
          find(detectObjects, ['id', boundingId])?.category
        );

        setCategorySlugByDetectObjectId((prev) => ({
          ...prev,
          [boundingId]: defaultCategorySlug
        }));
      }
    },
    [categorySlugByDetectObjectId, detectObjects]
  );

  const {
    createModelingDetectObjects,
    createModelingDetectObjectsErrorMessage,
    createModelingDetectObjectsLoading
  } = useCreateModelingDetectObjects({});

  const handleCreateDetectObjects = useCallback<() => Promise<void>>(() => {
    return createModelingDetectObjects({
      fileAttachmentId
    }).then((data) => {
      setFileAttachment(data.fileAttachment);
      setDetectObjects(data.detectObjects);
    });
  }, [fileAttachmentId, createModelingDetectObjects]);

  const handleSubmit = useCallback<() => void>(() => {
    onSubmit({
      fileAttachment,
      detectObject: find(detectObjects, ['id', selectedDetectObjectId]),
      allDetectObjects: detectObjects,
      modelingCategory: selectedModelingCategory
    });
    hideModal();
  }, [
    selectedDetectObjectId,
    selectedModelingCategory,
    detectObjects,
    fileAttachment,
    hideModal,
    onSubmit
  ]);

  return (
    <>
      <div className="p-4">
        <div className="text-sm mb-4">
          <p
            className={cl(
              step === ProductsAiSearchDetectModalButtonSteps.FIRST
                ? null
                : 'text-gray-500'
            )}
          >
            {'1. '}
            <Translate
              id={stringsKeys.uploadASinglePhotoOfTheRoomAndOurAIWill}
            />
          </p>
          <p
            className={cl(
              step === ProductsAiSearchDetectModalButtonSteps.SECOND
                ? null
                : 'text-gray-500'
            )}
          >
            {'2. '}
            <Translate
              id={stringsKeys.selectTheObjectYouWantToSearchInOur3DLibrary}
            />
          </p>
        </div>
        {step === ProductsAiSearchDetectModalButtonSteps.FIRST ? (
          <>
            {createModelingDetectObjectsLoading ? (
              <div className="p-4">
                <Loading addClass="w-full" />
              </div>
            ) : null}
            <DropzoneHelper
              type="fileAttachments"
              disabled={createModelingDetectObjectsLoading}
              withoutTabs
              maxFiles={1}
              preventMaxFilesOverload
              onChange={setUploadedFileIds}
            />
            <AlertMessage message={createModelingDetectObjectsErrorMessage} />
          </>
        ) : null}
        {step === ProductsAiSearchDetectModalButtonSteps.SECOND ? (
          <>
            <ProductsAiSearchImage
              detectObjects={detectObjects}
              fileAttachment={fileAttachment}
              selectedDetectObjectId={selectedDetectObjectId}
              onSelectChange={handleDetectObjectIdChange}
            />
            <div className="mt-4">
              <span className="text-sm text-left">
                <Translate id={productsKeys.category} />
              </span>

              <ModelingCategoriesSelect
                value={categorySlug}
                disabled={!selectedDetectObjectId}
                onChange={handleCategoryChange}
              />
            </div>
          </>
        ) : null}
      </div>
      <div className="flex p-4 space-x-1 justify-between">
        <div className="flex space-x-1" />

        <div className="flex space-x-1">
          <PureButtonHelper
            className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            i18nText={words.cancel}
            onClick={hideModal}
          />
          {step === ProductsAiSearchDetectModalButtonSteps.FIRST ? (
            <PureButtonHelper
              className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
              i18nText={words.search}
              disabled={!fileAttachmentId || createModelingDetectObjectsLoading}
              onClick={handleCreateDetectObjects}
            />
          ) : null}
          {step === ProductsAiSearchDetectModalButtonSteps.SECOND ? (
            <PureButtonHelper
              className="py-2 pl-4 pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
              i18nText={words.search}
              disabled={!selectedDetectObjectId || !selectedModelingCategory}
              onClick={handleSubmit}
            />
          ) : null}
        </div>
      </div>
    </>
  );
}

export default ProductsAiSearchDetectModalButtonContent;
