import React from 'react';

import {
  ProductsAiSearchFileAttachment,
  ProductsAiSearchDetectObject,
  ProductsAiSearchModelingCategory,
  ProductsAiSearchOnDetect
} from '../../ProductsAiSearch.types';

import { useFileAttachmentDimensions } from '../../../../../../fileAttachments/hooks/useFileAttachmentDimensions';

import { ProductsAiSearchDetectModalButton } from '../ProductsAiSearchDetectModalButton';

import { productsAiSearchImageGetPercentagePosition } from '../../utils/productsAiSearchImageGetPercentagePosition';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';

interface ProductsAiSearchDetectFileAttachmentPreviewProps {
  fileAttachment: ProductsAiSearchFileAttachment;
  detectObject: ProductsAiSearchDetectObject;
  allDetectObjects: ProductsAiSearchDetectObject[];
  modelingCategory: ProductsAiSearchModelingCategory;
  onDetectAiSearch: ProductsAiSearchOnDetect;
}

function ProductsAiSearchDetectFileAttachmentPreview({
  fileAttachment,
  detectObject,
  allDetectObjects,
  modelingCategory,
  onDetectAiSearch
}: ProductsAiSearchDetectFileAttachmentPreviewProps) {
  const imageDimensions = useFileAttachmentDimensions(fileAttachment);

  const detectLeftPercentage = productsAiSearchImageGetPercentagePosition(
    imageDimensions.width,
    detectObject.x1
  );

  const detectRightPercentage = productsAiSearchImageGetPercentagePosition(
    imageDimensions.width,
    detectObject.x2,
    {
      reverse: true
    }
  );

  const detectTopPercentage = productsAiSearchImageGetPercentagePosition(
    imageDimensions.height,
    detectObject.y1
  );

  const detectBottomPercentage = productsAiSearchImageGetPercentagePosition(
    imageDimensions.height,
    detectObject.y2,
    { reverse: true }
  );

  return (
    <div className="h-20">
      <ProductsAiSearchDetectModalButton
        className="block overflow-hidden focus-focus-visible:ring-offset-0 rounded-md"
        initialDetectObjects={allDetectObjects}
        initialFileAttachment={fileAttachment}
        initialSelectedDetectObjectId={detectObject?.id}
        initialModelingCategorySlug={modelingCategory?.slug}
        onSubmit={onDetectAiSearch}
      >
        {imageDimensions?.width && imageDimensions?.height ? (
          <div key={detectObject.id} className="relative">
            <ImageHelper
              className="max-h-20 max-w-36"
              src={fileAttachment.file}
              alt={fileAttachment.name}
              version={FileAttachmentItemImageVersions.FullScreenThumb1000x850}
              tooltipAlt
              tooltipAltInteractive
              tooltipAltAppendToBody
            />

            <div
              style={{
                left: `${detectLeftPercentage}%`,
                right: `${detectRightPercentage}%`,
                top: `${detectTopPercentage}%`,
                bottom: `${detectBottomPercentage}%`
              }}
              className="absolute ring-2 bg-transparent hover:bg-opacity-20 hover:bg-white ring-green-600 bg-opacity-20 bg-white"
            />
          </div>
        ) : null}
      </ProductsAiSearchDetectModalButton>
    </div>
  );
}
export default ProductsAiSearchDetectFileAttachmentPreview;
